import type {
  IAccountRedirectSelector,
  IAccountStore,
  IDataForUploadPage,
} from '../types';
import type { IGetProfileSelector } from '../types';

export const signInSelector = (
  state: IAccountStore,
): Pick<IAccountStore, 'signIn'> => ({
  signIn: state.signIn,
});

export const registrationSelector = (
  state: IAccountStore,
): IAccountStore['registration'] => {
  return state.registration;
};

export const getIsLoggedInSelector = (
  state: IAccountStore,
): IAccountStore['isLoggedIn'] => state.isLoggedIn;

export const getProfileSelector = (
  state: IAccountStore,
): IGetProfileSelector => ({
  user: state.user,
  signOut: state.signOut,
  getUser: state.getUser,
  deleteAccount: state.deleteAccount,
  updateProfile: state.updateProfile,
  removeAvatar: state.removeAvatar,
});

export const verifyPageSelector = (
  state: IAccountStore,
): Pick<IAccountStore, 'signOut' | 'resendVerify'> => ({
  signOut: state.signOut,
  resendVerify: state.resendVerify,
});

export const verifyEmailSelector = (
  state: IAccountStore,
): IAccountStore['verifyEmail'] => {
  return state.verifyEmail;
};

export const uploadSelector = (
  state: IAccountStore,
): IAccountStore['uploadVideo'] => {
  return state.uploadVideo;
};

export const uploadPageSelector = (
  state: IAccountStore,
): IDataForUploadPage => ({
  video: state.user?.video,
  getUser: state.getUser,
  isLoggedIn: state.isLoggedIn,
});

export const getLayoutSelector = (
  state: IAccountStore,
): Pick<IAccountStore, 'user' | 'isLoggedIn'> => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
});

export const accountRedirectSelector = (
  state: IAccountStore,
): IAccountRedirectSelector => ({
  isLoggedIn: state.isLoggedIn,
  isVerified: !!state.user?.verified_at,
});

export const recoverySelector = (
  state: IAccountStore,
): Pick<IAccountStore, 'forgotPassword' | 'resetPassword'> => ({
  forgotPassword: state.forgotPassword,
  resetPassword: state.resetPassword,
});
