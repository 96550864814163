import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { getIsLoading, useAppStore } from 'store';

import { useTranslate } from 'hooks';

import {
  BaseButton,
  BaseButtonTemplates,
  Input,
  Loader,
  TextButton,
} from 'components';
import { useFormik } from 'formik';
import i18n from 'i18next';
import { object, string } from 'yup';

import type { RecoveryFormProps } from './types';
import type { IRecoveryForm } from './types';

const validationSchema = object({
  email: string()
    .email(i18n.t('validation.emailError'))
    .required(i18n.t('validation.requiredEmail')),
});

export const RecoveryForm = (props: RecoveryFormProps): ReactElement => {
  const { onSubmit } = props;
  const { t } = useTranslate('recoveryPage');
  const navigate = useNavigate();
  const isLoading = useAppStore(getIsLoading);

  const { dirty, getFieldProps, errors, touched, handleSubmit } =
    useFormik<IRecoveryForm>({
      initialValues: {
        email: '',
      },
      onSubmit: async (values, formikHelpers) => {
        await onSubmit({ email: values.email });
        formikHelpers.resetForm();
      },
      validationSchema,
    });

  const onClickSignIn = useCallback((): void => {
    navigate(`${Paths.BaseAuth}/${Paths.SignIn}`);
  }, [navigate]);

  return (
    <>
      <form onSubmit={handleSubmit} className={'flex-center flex-col gap-4'}>
        <Input
          placeholder={t('emailPlaceholder')}
          hasError={!!errors.email && touched.email}
          errorText={errors.email}
          {...getFieldProps('email')}
        />
        <BaseButton
          type={'submit'}
          template={BaseButtonTemplates.Fill}
          className={'w-full'}
          disabled={!dirty || !!errors.email}
        >
          {isLoading ? <Loader /> : t('resetPassword')}
        </BaseButton>
      </form>
      <TextButton title={t('signIn')} onClick={onClickSignIn} />
    </>
  );
};
