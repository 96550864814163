import type { ChangeEvent, ReactElement } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { uploadSelector, useAccountStore } from 'store';

import { Orientations, useCreateThumbnail, useTranslate } from 'hooks';

import { VideoView } from '../../../components';
import { Rules } from '../Rules';
import { UploadVideoCard } from '../UploadVideoCard';
import { BaseButton, EditableTitle, Loader, OptionsModal } from 'components';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';

export interface IUploadForm {
  title: string;
  description: string;
  thumbnail: File | null;
  video: File | null;
  duration: number;
  orientation: Orientations | '';
  format: string;
}

export const UploadLayout = (): ReactElement => {
  const { t } = useTranslate('uploadLayout');
  const uploadVideo = useAccountStore(uploadSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(true);

  const validationSchema = useMemo(
    () =>
      object({
        title: string()
          .max(50, t('titleLengthError'))
          .required(t('requiredTitle')),
        description: string()
          .max(200, t('descriptionLengthError'))
          .required(t('requiredDescription')),
        duration: number().min(5).max(15),
        orientation: string().oneOf([Orientations.Portrait]).required(),
        format: string().matches(/mp4/).required(),
      }),
    [t],
  );

  const {
    setFieldValue,
    errors,
    values,
    getFieldProps,
    handleSubmit,
    touched,
    handleReset,
  } = useFormik<IUploadForm>({
    initialValues: {
      title: '',
      description: '',
      thumbnail: null,
      video: null,
      duration: 0,
      orientation: '',
      format: '',
    },
    onSubmit: async (values) => {
      if (!values.video || !values.thumbnail) return;

      const formData: FormData = new FormData();

      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('file', values.video);
      formData.append('thumbnail', values.thumbnail);

      setIsLoading(true);
      await uploadVideo(formData);
      setIsLoading(false);
    },
    onReset: () => {
      if (!videoRef.current) return;

      setVideoUrl(null);
      videoRef.current.src = '';
    },
    validationSchema,
  });

  useCreateThumbnail(
    {
      ref: videoRef,
      fileName: 'thumbnail.png',
      onSuccess: (image, orientation): void => {
        setFieldValue('thumbnail', image);
        setFieldValue('orientation', orientation);
      },
    },
    [values.video],
  );

  const toggleVideoPlayback = async (): Promise<void> => {
    const video = videoRef?.current;
    if (!video) return;

    if (video.paused) {
      setIsOpen(false);
      setShowPlayButton(false);
      await video.play();
      return;
    }

    video.pause();
  };

  const onFilePick = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    setVideoUrl(URL.createObjectURL(file));
    setFieldValue('video', file);
    setFieldValue('format', file.type);

    if (isOpen) {
      setIsOpen(false);
    }
  };

  const onClickAddVideo = useCallback((): void => {
    inputRef.current?.click();
  }, []);

  const handleLoadedMetadata = useCallback((): void => {
    if (!videoRef.current) return;
    setFieldValue('duration', videoRef.current.duration);
  }, []);

  const onPlayVideo = useCallback(() => {
    if (!videoRef.current?.paused) {
      videoRef.current?.pause();
      return;
    }

    setIsOpen(true);
  }, []);

  return (
    <>
      <h3
        className={
          'text-1.5xl font-medium text-gray-700 mb-7.5 mt-4 ' +
          'leading-8 w-[21.3125rem] text-center'
        }
      >
        {t('title')}
      </h3>
      {!videoUrl && (
        <UploadVideoCard
          uploadPlaceholder={t('uploadPlaceholder')}
          onClickAddVideo={onClickAddVideo}
        />
      )}
      <input
        ref={inputRef}
        type='file'
        accept={'video/*'}
        onChange={onFilePick}
        className={'hidden'}
      />
      <form
        className={'flex-center flex-col'}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {videoUrl && (
          <div className={'flex-center'}>
            <VideoView
              videoRef={videoRef}
              src={videoUrl}
              onLoadedMetadata={handleLoadedMetadata}
              onPlayVideo={onPlayVideo}
              showPlayButton={showPlayButton}
              onShowPlayButton={setShowPlayButton}
              invalidFormat={!!errors.format}
              disableBlur
            >
              <div className={'flex flex-col gap-1 mb-4'}>
                <EditableTitle
                  autoFocus
                  title={'Title'}
                  {...getFieldProps('title')}
                  hasError={!!errors.title && touched.title}
                  errorText={errors.title}
                />
                <EditableTitle
                  autoFocus
                  title={'Description'}
                  {...getFieldProps('description')}
                  hasError={!!errors.description && touched.description}
                  errorText={errors.description}
                />
              </div>
            </VideoView>
          </div>
        )}
        <Rules errors={errors} />
        {videoUrl && (
          <div className={'flex-center w-full mt-4 px-4'}>
            <BaseButton
              type={'submit'}
              maxSizeEnable={false}
              className={'w-full sm:w-100 '}
              disabled={Object.keys(errors).length !== 0}
            >
              {isLoading ? <Loader /> : t('submitButton')}
            </BaseButton>
          </div>
        )}
        <OptionsModal
          isOpen={isOpen}
          setModalOpen={setIsOpen}
          options={[
            { title: 'Change video', action: onClickAddVideo },
            { title: 'Watch video', action: toggleVideoPlayback },
            { title: 'Cancel', action: () => setIsOpen(false) },
          ]}
        />
      </form>
    </>
  );
};
