import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import { getIsLoggedInSelector, useAccountStore } from 'store';

import { useIsPage, useTranslate } from 'hooks';

export const Privacy = (): ReactElement => {
  const { t } = useTranslate('tabBar');
  const isLoggedIn = useAccountStore(getIsLoggedInSelector);
  const isVotePage = useIsPage(Paths.Vote);

  const newTabProps =
    !isLoggedIn && !isVotePage
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};

  return (
    <div className={'flex flex-col justify-center items-end'}>
      <span className={'text-sm md:text-base'}>{t('privacy')}</span>
      <Link
        to={Paths.Terms}
        {...newTabProps}
        className={
          'press-effect text-sm whitespace-nowrap md:text-base hover:text-orange-300 transition-all cursor-pointer'
        }
      >
        {t('terms')}
      </Link>
    </div>
  );
};
