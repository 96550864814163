import type { MouseEventHandler, ReactElement } from 'react';

import { ContestCandidate } from '../../pages/HomeSection/components';
import { BaseModal } from '../BaseModal';

import type { ModalProps } from './types';

export const VideoModal = (props: ModalProps): ReactElement | null => {
  const {
    video,
    isOpen = false,
    setModalOpen,
    disableBlur,
    setVideoStatus,
  } = props;

  const onClose: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setModalOpen?.(false);
  };

  if (!isOpen) return null;

  return (
    <BaseModal
      onClose={onClose}
      className={'h-[90vh] max-h-200 overflow-y-auto overflow-x-hidden'}
    >
      <ContestCandidate
        video={video}
        disableBlur={disableBlur}
        setWatchStatus={setVideoStatus}
      />
    </BaseModal>
  );
};
