import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { useIsPage } from 'hooks';

import { Images } from 'assets/images';

export const TermsPage = (): ReactElement => {
  const { t } = useTranslation();
  const isTermsPage = useIsPage(Paths.Terms);
  const navigate = useNavigate();

  const logoStyles = isTermsPage ? 'cursor-pointer press-effect' : '';

  const onClickLogo = (): void => {
    navigate(`/${Paths.Vote}`);
  };

  return (
    <div
      className={
        'flex flex-col h-[calc(100dvh-70px)] overflow-y-scroll ml-10 pr-10 pt-12'
      }
    >
      <div className={'flex items-center gap-1 mb-8'}>
        <Images.Logo
          className={`w-16 h-16 ${logoStyles}`}
          onClick={onClickLogo}
        />
        <Images.Name className={'w-[126px] h-[18px]'} />
      </div>
      <span className={'paymerobot-text text-3xl font-bold mb-3'}>
        {t('termsTitle')}
      </span>
      <span className={'paymerobot-text mb-[43px]'}>
        {`${t('lastUpdateTerms')} ${
          process.env.REACT_APP_LAST_UPDATE_TERMS_DATE
        }`}
      </span>
      <span className={'paymerobot-text font-medium'}>{t('rulesTitle')}</span>
      <span className={'paymerobot-text whitespace-pre-line ml-[30px]'}>
        {t('rules')}
      </span>
    </div>
  );
};
