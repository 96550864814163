import { OS } from 'types/defaultTypes';

export const getOS = (): OS => {
  const userAgent = navigator.userAgent;

  switch (true) {
    case /iPhone/.test(userAgent) || /iPad/.test(userAgent):
      return OS.Ios;
    case /Android/.test(userAgent):
      return OS.Android;
    default:
      return OS.Web;
  }
};

export const getDeepLink = (url: string): string => {
  return `paymerobot://${url}`;
};
