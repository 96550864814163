import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { useTranslate } from 'hooks';

import { Images } from 'assets/images';
import { BaseButton } from 'components';

export const SignUpBox = (): ReactElement => {
  const { t } = useTranslate('signUpBox');
  const navigate = useNavigate();

  const onClickSignIn = useCallback((): void => {
    navigate(`${Paths.BaseAuth}/${Paths.SignUp}`);
  }, []);

  return (
    <div
      className={
        'overflow-hidden relative h-45 bg-error-200 rounded-0.5xl mt-5 sm:mt-9.5 w-[20.9375rem]'
      }
    >
      <Images.Lines className={'absolute -top-[140px] -left-[140px]'} />
      <span
        className={
          'absolute block w-[calc(100%-54px)] text-constants-white text-2xl ' +
          'font-medium tracking-tight text-center my-5 left-1/2 -translate-x-1/2'
        }
      >
        {t('infoText')}
      </span>
      <BaseButton
        onClick={onClickSignIn}
        className={
          'absolute w-[calc(100%-100px)] left-1/2 -translate-x-1/2 bottom-5'
        }
        colour={'bg-constants-white'}
      >
        <span className={'text-constants-black'}>{t('buttonText')}</span>
      </BaseButton>
    </div>
  );
};
