import type { ReactElement } from 'react';

import type { LoaderProps } from './types';

export const Loader = (props: LoaderProps): ReactElement => {
  const { colour = 'primary', size = 'small' } = props;

  const loaderColour =
    colour === 'primary' ? 'border-constants-white' : 'border-orange-300';
  const getLoaderSize = () => {
    switch (size) {
      case 'small': {
        return 'w-6 h-6';
      }
      case 'medium': {
        return 'w-12 h-12';
      }
      case 'large': {
        return 'w-16 h-16';
      }
    }
  };

  return (
    <div
      className={`${getLoaderSize()} border-t-2 ${loaderColour} rounded-full animate-spin`}
    />
  );
};
