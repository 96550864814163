import type { ReactElement } from 'react';
import { memo } from 'react';

import { useTranslate } from 'hooks';

import { Icons } from 'assets/icons';
import { Images } from 'assets/images';
import { BaseButton, BaseButtonTemplates } from 'components';

import type { ButtonsPanelProps } from './types';

export const ButtonsPanel = memo((props: ButtonsPanelProps): ReactElement => {
  const { voteForVideo, isVoted, disableButtons } = props;
  const { t } = useTranslate('voteControlPanel');

  const disablePushTitleStyles = disableButtons
    ? 'text-gray-500'
    : 'text-orange-300';

  if (isVoted) {
    return (
      <div className={'flex-center h-56 text-gray-600 text-sm'}>
        {t('successfullyVote')}
      </div>
    );
  }

  return (
    <>
      <div className={'flex flex-col gap-3 mt-5 mb-2.5'}>
        <BaseButton
          className={'gap-2.5 w-56 h-13 px-0 py-0'}
          onClick={voteForVideo(1)}
          addDisableStyle={disableButtons}
        >
          <Icons.LeftArrow />
          <span>{t('vote')}</span>
        </BaseButton>
        <BaseButton
          className={'gap-2.5 w-56 h-13 px-0 py-0'}
          onClick={voteForVideo(2)}
          addDisableStyle={disableButtons}
        >
          <span>{t('vote')}</span>
          <Icons.RightArrow />
        </BaseButton>
        <BaseButton
          template={BaseButtonTemplates.Empty}
          className={'gap-5 h-13 px-0 py-0'}
          onClick={voteForVideo()}
          addDisableStyle={disableButtons}
        >
          <Images.Logo
            className={`w-[40px] h-[40px] ${
              disableButtons ? 'opacity-30' : ''
            }`}
          />
          <span className={`${disablePushTitleStyles} text-3xl`}>
            {t('push')}
          </span>
        </BaseButton>
      </div>
      <span className={'text-gray-600 text-sm'}>{t('pushPlaceholder')}</span>
    </>
  );
});

ButtonsPanel.displayName = 'ButtonsPanel';
