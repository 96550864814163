import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Paths } from 'common/constants/paths';
import { FLEX_CENTER, PRESS_EFFECT } from 'common/constants/styles';

import { useTranslate } from 'hooks';

import { useSignUpForm } from '../../hooks/useSignUpForm';
import {
  BaseButton,
  BaseButtonTemplates,
  Checkbox,
  Input,
  Loader,
  TextButton,
} from 'components';

export const SignUpForm = (): ReactElement => {
  const { t } = useTranslate('signUpPage');
  const navigate = useNavigate();

  const { handleSubmit, disabledSubmit, formConfig, checkBoxData, isLoading } =
    useSignUpForm();

  const onClickSignIn = useCallback((): void => {
    navigate(`${Paths.BaseAuth}/${Paths.SignIn}`);
  }, [navigate]);

  const renderFields = formConfig.map((field) => {
    return (
      <Input
        key={field.name}
        {...field}
        placeholder={t(`${field.name}Placeholder`)}
      />
    );
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={`${FLEX_CENTER} flex-col gap-4`}>
          <div className={`${FLEX_CENTER} flex-col gap-4`}>{renderFields}</div>
          <div className={'flex'}>
            <Checkbox id={'agree'} {...checkBoxData} />
            <label
              htmlFor={'agree'}
              className={
                'ml-2 select-none cursor-pointer text-sm font-medium text-gray-600'
              }
            >
              {t('agreeTitle')}
            </label>
            <Link
              to={`/${Paths.Terms}`}
              target='_blank'
              rel='noopener noreferrer'
              className={
                `${PRESS_EFFECT} ml-1 select-none cursor-pointer ` +
                'text-blue-200 underline text-sm font-medium'
              }
            >
              {t('termsTitle')}
            </Link>
          </div>
          <BaseButton
            type={'submit'}
            template={BaseButtonTemplates.Fill}
            className={'w-full'}
            disabled={disabledSubmit}
          >
            {isLoading ? <Loader /> : t('confirm')}
          </BaseButton>
        </div>
      </form>
      <TextButton title={t('signIn')} onClick={onClickSignIn} />
    </>
  );
};
