import type { ReactElement } from 'react';

import type { TypographyProps } from './types';

export const Typography = (props: TypographyProps): ReactElement => {
  const {
    title,
    children,
    className,
    withContainer = false,
    textStyle,
  } = props;

  if (!withContainer) {
    return (
      <span className={'text-gray-700 text-xl font-medium leading-6.5'}>
        {title}
      </span>
    );
  }

  return (
    <div className={className ?? 'flex flex-col'}>
      <span
        className={`text-gray-700 text-xl font-medium leading-6.5 ${textStyle}`}
      >
        {title}
      </span>
      {children}
    </div>
  );
};
