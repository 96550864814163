import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { getIsLoading, useAppStore } from 'store';

import { useTranslate } from 'hooks';

import {
  BaseButton,
  BaseButtonTemplates,
  Input,
  Loader,
  TextButton,
} from 'components';
import { useFormik } from 'formik';
import i18n from 'i18next';
import { object, ref, string } from 'yup';

import type { IResetForm, ResetPasswordFormProps } from './types';

const validationSchema = object().shape({
  newPassword: string().when('password', {
    is: (val: string) => val && val.length > 0,
    then: (schema) =>
      schema
        .min(8, i18n.t('validation.passwordMin'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
          i18n.t('validation.passwordPatterns'),
        )
        .required(i18n.t('validation.requiredPassword')),
  }),
  confirmPassword: string().when('newPassword', {
    is: (val: string) => val && val.length > 0,
    then: (schema) =>
      schema
        .oneOf([ref('newPassword')], i18n.t('validation.confirmPassword'))
        .required(i18n.t('validation.requiredPassword')),
  }),
});

export const ResetPasswordForm = (
  props: ResetPasswordFormProps,
): ReactElement => {
  const { token, onSubmit } = props;
  const { t } = useTranslate('recoveryPage');
  const navigate = useNavigate();
  const isLoading = useAppStore(getIsLoading);

  const { dirty, handleSubmit, errors, touched, getFieldProps } =
    useFormik<IResetForm>({
      initialValues: {
        newPassword: '',
        confirmPassword: '',
      },
      onSubmit: async (values) => {
        await onSubmit({
          password: values.newPassword,
          token,
          password_confirmation: values.confirmPassword,
        });
        onClickSignIn();
      },
      validationSchema,
    });

  const onClickSignIn = useCallback((): void => {
    navigate(`${Paths.BaseAuth}/${Paths.SignIn}`);
  }, [navigate]);

  const disabledSubmit = !dirty || Object.keys(errors).length !== 0;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={'flex-center flex-col gap-4'}>
          <Input
            {...getFieldProps('newPassword')}
            type={'password'}
            //TODO: move to locale
            placeholder={'New password'}
            hasError={!!errors.newPassword && touched.newPassword}
            errorText={errors.newPassword}
          />
          <Input
            {...getFieldProps('confirmPassword')}
            type={'password'}
            //TODO: move to locale
            placeholder={'Confirm password'}
            hasError={!!errors.confirmPassword && touched.confirmPassword}
            errorText={errors.confirmPassword}
          />
          <BaseButton
            type={'submit'}
            template={BaseButtonTemplates.Fill}
            className={'w-full'}
            disabled={disabledSubmit}
          >
            {/*TODO: move to locale*/}
            {isLoading ? <Loader /> : 'Confirm'}
          </BaseButton>
        </div>
      </form>
      <TextButton title={t('signIn')} onClick={onClickSignIn} />
    </>
  );
};
