import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import {
  getIsLoading,
  uploadPageSelector,
  useAccountStore,
  useAppStore,
} from 'store';

import { ApprovedLayout, SuccessfulUpload, UploadLayout } from './components';
import { ContainerWithLogo, Loader } from 'components';

import { VideoStatuses } from 'api/services/types';

export const UploadPage = (): ReactElement => {
  const { getUser, video, isLoggedIn } = useAccountStore(uploadPageSelector);
  const isLoading = useAppStore(getIsLoading);

  const renderLayout = (): ReactElement => {
    switch (video?.status) {
      case VideoStatuses.Disapproved: {
        return <UploadLayout />;
      }
      case VideoStatuses.Uploaded: {
        return <SuccessfulUpload video={video} />;
      }
      case VideoStatuses.InThePool: {
        return <ApprovedLayout align={'center'} />;
      }
      case VideoStatuses.InTheContest: {
        return (
          <ApprovedLayout
            title={'congrats'}
            firstDescription={'firstDescriptionContest'}
            secondDescription={'secondDescriptionContest'}
            align={'center'}
          />
        );
      }
      default: {
        return <UploadLayout />;
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUser();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Navigate to={`/${Paths.Vote}`} />;
  }

  return (
    <ContainerWithLogo>
      {isLoading && (
        <div className={'mt-32'}>
          <Loader colour={'secondary'} size={'large'} />
        </div>
      )}
      {!isLoading && renderLayout()}
    </ContainerWithLogo>
  );
};
