import { useEffect, useState } from 'react';

interface UseBreakpointReturn {
  breakpoint: number;
}

const deviceWidth = {
  empty: 0,
  usm: 376,
  sm: 480,
  md: 768,
  lg: 976,
  '0.5xl': 1200,
  xl: 1440,
};

export const getBreakpoint = (): number => {
  return (
    Object.values(deviceWidth)
      .reverse()
      .map(Number)
      .find((entry) => entry <= window.innerWidth) ?? deviceWidth.empty
  );
};

export const useBreakpoint = (): UseBreakpointReturn => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      const next = getBreakpoint();

      setBreakpoint(next);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { breakpoint };
};
