import type { ReactElement } from 'react';
import { useEffect } from 'react';

import { Portal } from './portal';
import { Icons } from 'assets/icons';

import type { BaseModalProps } from './types';

export const BaseModal = (props: BaseModalProps): ReactElement => {
  const { children, onClose, setIsOpen, hideClose = false, className } = props;

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setIsOpen?.(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <Portal>
      <div
        onClick={onClose}
        className={'fixed flex-center top-0 left-0 w-screen h-screen bg-shadow'}
      >
        <div className={'relative'}>
          <div className={'h-8 rounded-t-xl bg-constants-white'}>
            {!hideClose && (
              <div
                onClick={onClose}
                className={
                  'absolute top-0 right-0 flex-center w-8 h-8 rounded-full cursor-pointer'
                }
              >
                <Icons.Close />
              </div>
            )}
          </div>
          <div className={`bg-constants-white rounded-b-xl ${className}`}>
            <div className={'w-full p-4'}>{children}</div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
