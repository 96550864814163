import { type ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import { recoverySelector, useAccountStore } from 'store';

import { useMobileRedirect, useTranslate } from 'hooks';

import {
  AccountPageContainer,
  RecoveryForm,
  ResetPasswordForm,
} from '../components';

export const RecoveryPage = (): ReactElement => {
  const { t } = useTranslate('recoveryPage');
  const { forgotPassword, resetPassword } = useAccountStore(recoverySelector);

  const [params] = useSearchParams();
  const resetToken = params.get('token');

  useMobileRedirect({ resetToken });

  return (
    <AccountPageContainer title={t('title')}>
      {resetToken ? (
        <ResetPasswordForm token={resetToken} onSubmit={resetPassword} />
      ) : (
        <RecoveryForm onSubmit={forgotPassword} />
      )}
    </AccountPageContainer>
  );
};
