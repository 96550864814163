import type { ReactElement } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { PrevContest } from 'store';
import { getContestSelector, useContestStore } from 'store';

import { useTranslate } from 'hooks';

import { Column } from '../Column';
import { SignUpBox } from '../SignUpBox';
import { BaseButton, ContainerWithLogo, Loader } from 'components';

import type { ContestComingSoonProps } from './types';

//TODO: move titles to locale
export const ContestComingSoon = (
  props: ContestComingSoonProps,
): ReactElement => {
  const { t } = useTranslate('comingSoon');
  const { isLoggedIn } = props;
  const [startAnimation, setStartAnimation] = useState<boolean>(false);
  const { getCurrentContest, getPreviousContest } =
    useContestStore(getContestSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevContest, setPrevContest] = useState<PrevContest | null>(null);

  const onRefresh = async (): Promise<void> => {
    setIsLoading(true);
    await getCurrentContest();
    setIsLoading(false);
  };

  const video1 = prevContest?.video1 ?? 0;
  const video2 = prevContest?.video2 ?? 0;
  const push = prevContest?.push ?? 0;

  const total = Math.max(video1, video2, push);
  const { video1Height, video2Height, pushHeight } = useMemo(() => {
    const video1Height = Math.floor((video1 / total) * 100);
    const video2Height = Math.floor((video2 / total) * 100);
    const pushHeight = Math.floor((push / total) * 100);

    return { video1Height, video2Height, pushHeight };
  }, [prevContest]);

  const getPrev = async (): Promise<void> => {
    const prevContest = await getPreviousContest();

    if (prevContest) {
      setPrevContest(prevContest);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      getPrev();
      setIsLoading(false);
    }

    setStartAnimation(true);
  }, [isLoggedIn]);

  return (
    <ContainerWithLogo>
      <div className={'flex-center flex-col'}>
        <span className={'mt-3 mb-5 text-1.5xl font-bold'}>{t('title')}</span>
        <div className={'w-[20.9375rem] flex-center flex-col'}>
          <span className={'text-center mb-4'}>{t('firstDescription')}</span>
          <BaseButton onClick={onRefresh} className={'w-full'}>
            {isLoading ? <Loader /> : t('buttonTitle')}
          </BaseButton>
        </div>
        {isLoggedIn && !isLoading && (
          <div className={'w-[20.9375rem] flex-center flex-col'}>
            <span className={'text-center mt-3'}>{t('secondDescription')}</span>
            <div
              className={
                'flex justify-center gap-10 w-full h-[15.9375rem] comingSoonGradient rounded-0.5xl mt-4 p-4'
              }
            >
              <Column
                title={'Video 1'}
                height={video1Height}
                votes={video1}
                startAnimation={startAnimation}
              />
              <Column
                title={'Video 2'}
                height={video2Height}
                votes={video2}
                startAnimation={startAnimation}
              />
              <Column
                title={'Push'}
                height={pushHeight}
                votes={push}
                startAnimation={startAnimation}
              />
            </div>
          </div>
        )}
        {!isLoggedIn && <SignUpBox />}
      </div>
    </ContainerWithLogo>
  );
};
