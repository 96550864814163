import type { IAppStore } from '../types';

export const getIsLoading = (state: IAppStore): IAppStore['isLoading'] => {
  return state.isLoading;
};

export const getFingerprint = (state: IAppStore): IAppStore['fingerprint'] => {
  return state.fingerprint;
};

export const changeFpSelector = (
  state: IAppStore,
): IAppStore['setFingerprint'] => {
  return state.setFingerprint;
};
