import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import {
  ConfirmVerify,
  ErrorPage,
  ProfilePage,
  RecoveryPage,
  SignInPage,
  SignUpPage,
  TermsPage,
  UploadPage,
  VerifyPage,
  VotePage,
} from 'pages';

import { AccountRedirect } from './AccountRedirect';
import { Layout } from './Layout';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />} element={<Layout />}>
      <Route index element={<Navigate to={Paths.Vote} />} />
      <Route path={Paths.Upload} element={<UploadPage />} />
      <Route path={Paths.Vote} element={<VotePage />} />
      <Route path={Paths.Profile} element={<ProfilePage />} />
      <Route path={Paths.Terms} element={<TermsPage />} />
      {/*<Route path={Paths.About} element={<AboutPage />} />*/}
      <Route path={Paths.ConfirmVerify} element={<ConfirmVerify />} />
      <Route path={Paths.Recovery} element={<RecoveryPage />} />
      <Route path={Paths.RecoveryWithToken} element={<RecoveryPage />} />
      <Route path={Paths.Auth} element={<AccountRedirect />}>
        <Route index element={<Navigate to={Paths.SignIn} />} />
        <Route path={Paths.SignIn} element={<SignInPage />} />
        <Route path={Paths.SignUp} element={<SignUpPage />} />
        <Route path={Paths.Verify} element={<VerifyPage />} />
        <Route path={Paths.AnyOther} element={<Navigate to={Paths.SignIn} />} />
      </Route>
    </Route>,
  ),
);
