import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  getIsLoading,
  getIsLoggedInSelector,
  minimisedContentSelector,
  useAccountStore,
  useAppStore,
  useContestStore,
} from 'store';

import { useTranslate } from 'hooks';

import { ContestComingSoon } from '../ContestComingSoon';
import { Jackpot } from '../Jackpot';
import { MinimisedVideo } from '../MinimisedVideo';
import { SignUpBox } from '../SignUpBox';
import { Images } from 'assets/images';
import { BaseButton, BaseButtonTemplates, Loader, Timer } from 'components';

import { VoteStatus } from 'api/services/types';

export const MinimisedContent = (): ReactElement => {
  const { t } = useTranslate('voteControlPanel');
  const { t: tToasts } = useTranslate('toasts');
  const {
    jackpot,
    firstVideo,
    secondVideo,
    votingIsEnable,
    voting,
    isVoted,
    timeLeft,
  } = useContestStore(minimisedContentSelector);
  const isLoading = useAppStore(getIsLoading);
  const isLoggedIn = useAccountStore(getIsLoggedInSelector);

  const voteForVideo = useCallback(
    (videoNumber?: 1 | 2) => async (): Promise<void> => {
      if (!votingIsEnable) {
        toast.info(tToasts('bothWaring'));
        return;
      }

      if (videoNumber) {
        await voting(videoNumber);
        return;
      }

      await voting();
    },
    [votingIsEnable],
  );

  const disablePushTitleStyles = !votingIsEnable
    ? 'text-gray-500'
    : 'text-orange-300';

  if (isLoading && !firstVideo && !secondVideo) {
    return (
      <div className={'flex-center w-screen h-screen'}>
        <Loader size={'large'} colour={'secondary'} />
      </div>
    );
  }

  if (!firstVideo || !secondVideo) {
    return <ContestComingSoon isLoggedIn={isLoggedIn} />;
  }

  return (
    <div>
      <div className={'flex flex-col items-center'}>
        <Images.LogoWithTitle className={'w-20 h-20 mb-3'} />
        <Timer timeLeft={timeLeft} />
        <Jackpot jackpot={jackpot} title={t('jackpotMinMode')} minMode />
      </div>
      <div className={'flex flex-col items-center'}>
        <div className={'flex gap-5'}>
          <MinimisedVideo
            video={firstVideo}
            onVote={voteForVideo(1)}
            isVoted={isVoted === VoteStatus.Voted}
            disabled={!votingIsEnable}
            first
          />
          <MinimisedVideo
            video={secondVideo}
            onVote={voteForVideo(2)}
            isVoted={isVoted === VoteStatus.Voted}
            disabled={!votingIsEnable}
          />
        </div>
        {isVoted === VoteStatus.NotVoted ? (
          <>
            <BaseButton
              template={BaseButtonTemplates.Empty}
              className={'gap-5 h-13 px-0 py-0 mt-[22px]'}
              onClick={voteForVideo()}
              addDisableStyle={!votingIsEnable}
            >
              <Images.Logo
                className={`w-[40px] h-[40px] ${
                  !votingIsEnable ? 'opacity-30' : ''
                }`}
              />
              <span className={`${disablePushTitleStyles} text-3xl`}>
                {t('push')}
              </span>
            </BaseButton>
            <span className={'text-gray-600 text-sm mt-2.5'}>
              {t('pushPlaceholder')}
            </span>
          </>
        ) : (
          <div className={'flex-center my-6 text-gray-600 text-sm'}>
            {t('successfullyVote')}
          </div>
        )}
        {!isLoggedIn && <SignUpBox />}
      </div>
    </div>
  );
};
