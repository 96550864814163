import { DateTime } from 'luxon';

import type { IUser } from 'api/services/types';

export const serialiseUser = (updatedUser: IUser): IUser => {
  const newUser = updatedUser;

  if (newUser.video) {
    newUser.video.created_at = DateTime.fromFormat(
      newUser.video.created_at,
      'MM/dd/yyyy hh:mm:ss',
    ).toFormat('MM.dd.yyyy');
  }

  if (newUser.video?.contest?.date) {
    newUser.video.contest.date = DateTime.fromFormat(
      newUser.video.contest.date,
      'MM/dd/yyyy',
    ).toFormat('dd MMM yyyy');
  }

  return newUser;
};
