import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Paths } from 'common/constants/paths';

import {
  getIsLoading,
  registrationSelector,
  useAccountStore,
  useAppStore,
} from 'store';

import { useTranslate } from 'hooks';

import { HttpStatusCode } from 'axios';
import type { InputProps } from 'components';
import { useFormik } from 'formik';
import { boolean, object, ref, string } from 'yup';

import type { ISignUpValues, UseAccountFormReturn } from './types';

export const useSignUpForm = (): UseAccountFormReturn => {
  const { t } = useTranslate('validation');
  const registration = useAccountStore(registrationSelector);
  const navigate = useNavigate();
  const isLoading = useAppStore(getIsLoading);

  const validationSchema = useMemo(
    () =>
      object({
        name: string()
          .min(6, t('minNameLength'))
          .max(30, t('maxNameLength'))
          .matches(/^[a-zA-Z0-9_.-]+( [a-zA-Z0-9_.-]+)?$/, t('namePatterns'))
          .required(t('requiredName')),
        email: string().email(t('emailError')).required(t('requiredEmail')),
        password: string()
          .min(8, t('passwordMin'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
            t('passwordPatterns'),
          )
          .required(t('requiredPassword')),
        password_confirmation: string().oneOf(
          [ref('password')],
          t('confirmPassword'),
        ),
        terms: boolean(),
      }),
    [t],
  );

  const { handleSubmit, getFieldProps, errors, touched, values } =
    useFormik<ISignUpValues>({
      initialValues: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms: false,
      },
      onSubmit: async (values) => {
        const { message, statusCode, errors } = await registration(values);

        if (statusCode === HttpStatusCode.Ok) {
          navigate(`${Paths.BaseAuth}/${Paths.Verify}`);
          toast.success(message, { autoClose: 2000 });
          return;
        }

        if (errors?.email?.length) {
          toast.error(errors?.email[0]);
          return;
        }

        if (errors?.name?.length) {
          toast.error(errors?.name[0]);
          return;
        }

        if (statusCode >= 400 || statusCode === -1) {
          toast.error(message);
        }
      },
      validationSchema,
    });

  const formConfig: InputProps[] = [
    {
      ...getFieldProps('name'),
      type: 'text',
      hasError: !!errors.name && touched.name,
      errorText: errors.name,
    },
    {
      ...getFieldProps('email'),
      type: 'text',
      hasError: !!errors.email && touched.email,
      errorText: errors.email,
    },
    {
      ...getFieldProps('password'),
      type: 'password',
      hasError: !!errors.password && touched.password,
      errorText: errors.password,
    },
    {
      ...getFieldProps('password_confirmation'),
      type: 'password',
      hasError: !!errors.password_confirmation && touched.password_confirmation,
      errorText: errors.password_confirmation,
    },
  ];

  const hasError =
    !!errors.email ||
    !!errors.password ||
    !!errors.name ||
    !!errors.password_confirmation ||
    !!errors.terms;

  const hasEmptyField = Object.values(values).includes('' || false);

  return {
    formConfig,
    checkBoxData: getFieldProps('terms'),
    handleSubmit,
    disabledSubmit: hasError || hasEmptyField,
    isLoading,
  };
};
