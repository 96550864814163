import type { HttpStatusCode } from 'axios';

import type { StatusText } from 'types/defaultTypes';

enum UserStatuses {
  Active = 'active',
  Blocked = 'blocked',
}

export enum VideoStatuses {
  InThePool = 'in_the_pool',
  Disapproved = 'disapproved',
  Uploaded = 'uploaded',
  InTheContest = 'in_the_contest',
}

export interface IUser {
  avatar: string | null;
  email: string;
  id: number;
  name: string;
  status: UserStatuses;
  verified_at: string | null;
  video: IVideo | null;
}

export interface IContest {
  id: number;
  jackpot: number | null;
  date: string;
  push: number;
  voted: VoteStatus;
  status: 'in_voting' | string;
  time_left: number;
  push_amount: null;
  video_1: IVideo;
  video_2: IVideo;
}

export enum WatchedStatus {
  Unseen,
  Watched,
}

export enum VoteStatus {
  NotVoted,
  Voted,
}

export interface IVideo {
  id: number;
  title: string;
  description: string;
  url: string;
  thumbnail: string;
  status: VideoStatuses;
  created_at: string;

  contest?: IContest | null;
  watched?: WatchedStatus;
  //TODO: need check
  disapproved_reason?: null;
  player?: IPlayer;
  votes?: number;
  //TODO: need check
  win?: null;
}

interface IPlayer {
  avatar: string | null;
  id: number;
  name: string;
}

export interface IAccountResponse {
  user: IUser;
  token: string;
  message: string;
  statusText: StatusText;
  statusCode: HttpStatusCode;
}
