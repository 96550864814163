import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import type { IAppStore } from './types';

export const useAppStore = createWithEqualityFn<IAppStore>()(
  immer((set) => ({
    isLoading: false,
    fingerprint: null,
    toggleLoader: (): void => {
      set((state) => {
        state.isLoading = !state.isLoading;
      });
    },
    setFingerprint: async (fingerprint) => {
      set((state) => {
        state.fingerprint = fingerprint;
      });
    },
  })),
  shallow,
);
