import type { ReactElement } from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Paths } from 'common/constants/paths';

import {
  changeFpSelector,
  getLayoutSelector,
  useAccountStore,
  useAppStore,
} from 'store';

import { useIsPage } from 'hooks';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { TabBar } from 'components';

export const Layout = (): ReactElement => {
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const isTermsPage = useIsPage(Paths.Terms);

  const { user, isLoggedIn } = useAccountStore(getLayoutSelector);
  const setFingerprint = useAppStore(changeFpSelector);

  const handleRedirect = useCallback((): void => {
    if (isTermsPage) return;

    if (user && user.verified_at === null) {
      if (window.location.href.includes('verify/')) return;

      navigate(`${Paths.BaseAuth}/${Paths.Verify}`);
    }
  }, [user, navigate, token, isTermsPage]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  useEffect(() => {
    if (!isLoggedIn) {
      const getFingerprint = async (): Promise<void> => {
        const fp = await FingerprintJS.load();
        const { visitorId } = await fp.get();
        await setFingerprint(visitorId);
      };

      getFingerprint();
    }
  }, [isLoggedIn]);

  return (
    <div className={'flex flex-col'}>
      <div className={'flex-grow'}>
        <Outlet />
      </div>
      <TabBar />
      <ToastContainer />
    </div>
  );
};
