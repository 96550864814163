import type { AxiosInstance } from 'axios';

export const addAccessTokenInterceptor = (instance: AxiosInstance): number => {
  return instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });
};

export const removeRequestInterceptor = (
  interceptorId: number,
  instance?: AxiosInstance,
): void => {
  instance?.interceptors.request.eject(interceptorId);
};
