import type { ReactElement } from 'react';
import { useState } from 'react';

import { useTranslate } from 'hooks';

import { Icons } from 'assets/icons';
import { Typography, VideoModal } from 'components';
import { parseStatus } from 'utils/parseStatus';

import type { MyVideoProps } from './types';

export const MyVideos = (props: MyVideoProps): ReactElement => {
  const { video } = props;
  const { created_at, title, status, thumbnail } = video;
  const { t } = useTranslate('profilePage');

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = (): void => {
    setModalOpen(true);
  };

  return (
    <div className={'border rounded-0.5xl p-5 w-[20.9375rem]'}>
      <Typography title={t('myVideoTitle')} withContainer>
        <div className={'inline-flex gap-5'}>
          <div
            className={
              'flex-center mt-2 bg-orange-100 w-[7.9375rem] h-[5.625rem] ' +
              'relative rounded-lg overflow-hidden'
            }
          >
            <img src={thumbnail} alt={'thumbnail'} />
            <Icons.Play
              className={'absolute cursor-pointer'}
              width={16}
              height={18}
              onClick={openModal}
            />
          </div>
          <div className={'flex flex-col gap-2.5 pt-3'}>
            <span className={'text-xs text-gray-400'}>{created_at}</span>
            <Typography title={title} withContainer>
              <div className={'flex items-center gap-1.5'}>
                <span className={'text-sm text-gray-600 leading-5'}>
                  {parseStatus(status)}
                </span>
                {process.env.REACT_APP_MODE === 'dev' && (
                  <Icons.Question className={'cursor-pointer'} />
                )}
              </div>
            </Typography>
          </div>
        </div>
      </Typography>
      <VideoModal
        video={video}
        isOpen={modalOpen}
        setModalOpen={setModalOpen}
        disableBlur
      />
    </div>
  );
};
