import type { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import {
  getIsLoading,
  useAccountStore,
  useAppStore,
  verifyPageSelector,
} from 'store';

import { useTranslate } from 'hooks';

import { AccountPageContainer } from '../components';
import {
  BaseButton,
  BaseButtonTemplates,
  Loader,
  TextButton,
} from 'components';

export const VerifyPage = (): ReactElement => {
  const { t } = useTranslate('verifyPage');
  const { resendVerify, signOut } = useAccountStore(verifyPageSelector);
  const isLoading = useAppStore(getIsLoading);
  const navigate = useNavigate();

  const onClickSignOut = (): void => {
    signOut();
    navigate(Paths.BaseAuth);
  };

  return (
    <AccountPageContainer title={t('title')}>
      <span>{t('message')}</span>
      <BaseButton
        template={BaseButtonTemplates.Fill}
        className={'w-full'}
        onClick={resendVerify}
      >
        {isLoading ? <Loader /> : t('resend')}
      </BaseButton>
      <TextButton title={t('logout')} onClick={onClickSignOut} />
    </AccountPageContainer>
  );
};
