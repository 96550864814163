import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Icons } from 'assets/icons';

import type { InvalidFormatPlaceholderProps } from './types';

export const InvalidFormatPlaceholder = (
  props: InvalidFormatPlaceholderProps,
): ReactElement => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={
        'absolute flex-center flex-col bg-gray-700 rounded-xl h-full w-full p-6'
      }
    >
      <Icons.InvalidFormat
        className={'text-constants-white'}
        width={100}
        height={100}
      />
      <span className={'text-center text-constants-white mt-2'}>
        {t('incorrectFormatWarning')}
      </span>
    </div>
  );
};
