import { createRoot } from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './utils/i18n';
import { App } from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
