import type {
  IContestStore,
  IControlPanelSelector,
  IVotePageSelector,
} from '../types';
import type { IMinimisedContentSelector } from '../types';
import { VoteStatus } from 'api/services/types';

export const votePageSelector = (state: IContestStore): IVotePageSelector => ({
  firstVideo: state.contest?.video_1,
  secondVideo: state.contest?.video_2,
  getCurrentContest: state.getCurrentContest,
  setWatchStatus: state.setWatchStatus,
});

export const controlPanelSelector = (
  state: IContestStore,
): IControlPanelSelector => ({
  jackpot: state.contest?.jackpot ?? 0,
  votingIsEnable: state.votingIsEnable,
  isVoted: state.contest?.voted ?? VoteStatus.NotVoted,
  voting: state.voting,
  timeLeft: state.contest?.time_left ?? 0,
});

export const getContestSelector = (
  state: IContestStore,
): Pick<IContestStore, 'getCurrentContest' | 'getPreviousContest'> => ({
  getCurrentContest: state.getCurrentContest,
  getPreviousContest: state.getPreviousContest,
});

export const minimisedContentSelector = (
  state: IContestStore,
): IMinimisedContentSelector => ({
  jackpot: state.contest?.jackpot ?? 0,
  firstVideo: state.contest?.video_1,
  secondVideo: state.contest?.video_2,
  votingIsEnable: state.votingIsEnable,
  voting: state.voting,
  timeLeft: state.contest?.time_left ?? 0,
  isVoted: state.contest?.voted ?? VoteStatus.NotVoted,
});

export const getCurrentContestSelector = (
  state: IContestStore,
): IContestStore['getCurrentContest'] => {
  return state.getCurrentContest;
};
