import type { ReactElement } from 'react';

import { FLEX_CENTER } from 'common/constants/styles';

import { ContainerWithLogo } from 'components';

import type { AccountPageContainerProps } from './types';

export const AccountPageContainer = (
  props: AccountPageContainerProps,
): ReactElement => {
  const { children, title } = props;

  return (
    <ContainerWithLogo>
      <h3
        className={
          'text-1.5xl font-medium text-gray-700 mb-7.5 ' +
          'leading-8 w-[21.3125rem] text-center'
        }
      >
        {title}
      </h3>
      <div className={`${FLEX_CENTER} flex-col gap-6 w-screen`}>{children}</div>
    </ContainerWithLogo>
  );
};
