import { toast } from 'react-toastify';

import { AccountApiPath } from 'common/constants/paths';

import {
  addAccessTokenInterceptor,
  removeRequestInterceptor,
} from 'api/helpers';
import { baseInstance } from 'api/instance';

import type { IResponse, StatusText } from 'types/defaultTypes';
import type {
  IForgotDto,
  IGetUserResponse,
  ILoginDto,
  IRegistrationDto,
  IResetDto,
  IVerifyEmailDto,
  IVerifyEmailResponse,
  IVerifyResponse,
} from './types';
import type { IAccountResponse, IUser, IVideo } from 'api/services/types';

export const accountApi = {
  async signIn(payload: ILoginDto): Promise<IAccountResponse> {
    const { data, statusText, status } =
      await baseInstance.post<IAccountResponse>(AccountApiPath.Login, payload);

    return {
      ...data,
      statusText: statusText as StatusText,
      statusCode: status,
    };
  },
  async registration(payload: IRegistrationDto): Promise<IAccountResponse> {
    const { data, statusText, status } =
      await baseInstance.post<IAccountResponse>(
        AccountApiPath.Register,
        payload,
      );

    return {
      ...data,
      statusText: statusText as StatusText,
      statusCode: status,
    };
  },
  async resendVerify(): Promise<void> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const response = await baseInstance.get<IVerifyResponse>(
      AccountApiPath.ResendVerify,
    );

    removeRequestInterceptor(interceptorId, baseInstance);

    if (response.data.message) {
      toast.success(response.data.message);
    }
  },
  async verifyEmail(payload: IVerifyEmailDto): Promise<IResponse> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const { token, userId, expires, signature } = payload;
    const { data, statusText, status } =
      await baseInstance.get<IVerifyEmailResponse>(
        `${AccountApiPath.VerifyEmail}/${userId}/${token}?expires=${expires}&signature=${signature}`,
      );

    removeRequestInterceptor(interceptorId, baseInstance);

    return {
      message: data.message,
      statusCode: status,
      statusText: statusText as StatusText,
    };
  },
  async getCurrentUser(): Promise<IUser> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const response = await baseInstance.get<IGetUserResponse>(
      AccountApiPath.GetCurrentUser,
    );

    removeRequestInterceptor(interceptorId, baseInstance);

    return response.data.data;
  },
  async uploadVideo(data: FormData): Promise<IVideo> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);

    const response = await baseInstance.post<IVideo>(
      AccountApiPath.UploadVideo,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    removeRequestInterceptor(interceptorId, baseInstance);

    const { url, id, status, description, thumbnail, title, created_at } =
      response.data;

    return { url, id, status, description, thumbnail, title, created_at };
  },
  async deleteAccount(): Promise<string> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const response = await baseInstance.post(
      `${AccountApiPath.Users}${AccountApiPath.DeleteAccount}`,
    );
    removeRequestInterceptor(interceptorId, baseInstance);

    return response.statusText;
  },
  async updateProfile(formData: FormData): Promise<IUser> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);

    const response = await baseInstance.post(AccountApiPath.Users, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    removeRequestInterceptor(interceptorId, baseInstance);

    return response.data;
  },
  async forgotPassword(payload: IForgotDto): Promise<string> {
    const response = await baseInstance.post<{ message: string }>(
      AccountApiPath.Recovery,
      payload,
    );

    return response.data.message;
  },
  async resetPassword(payload: IResetDto): Promise<string> {
    const response = await baseInstance.post<{ message: string }>(
      AccountApiPath.ResetPassword,
      payload,
    );

    return response.data.message;
  },
  async removeAvatar(): Promise<IUser> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const response = await baseInstance.delete<IUser>(
      `${AccountApiPath.Users}${AccountApiPath.Avatars}`,
    );

    removeRequestInterceptor(interceptorId, baseInstance);

    return response.data;
  },
};
