import type { ReactElement } from 'react';
import { memo, useEffect, useState } from 'react';

import { InvalidFormatPlaceholder } from '../InvalidFormatPlaceholder';
import { Icons } from 'assets/icons';
import { Skeleton } from 'components';

import type { VideoViewProps } from './types';
import { WatchedStatus } from 'api/services/types';

export const VideoView = memo((props: VideoViewProps): ReactElement => {
  const {
    src,
    children,
    videoRef,
    onLoadedMetadata,
    uploadMode = false,
    status,
    changeVideoStatus,
    onPlayVideo,
    showPlayButton,
    onShowPlayButton,
    disableBlur,
    invalidFormat,
  } = props;

  const [loaded, setLoaded] = useState<boolean>(!!onLoadedMetadata);
  const [hideBlur, setHideBlur] = useState<boolean>(
    status === WatchedStatus.Watched,
  );

  const containerStyles: string = uploadMode
    ? 'w-100 border border-gray-800'
    : 'w-84';

  const blurEffect: string = !hideBlur && !disableBlur ? 'opacity-50' : '';

  const toggleVideoPlayback = async (): Promise<void> => {
    const video = videoRef?.current;
    if (!video) return;

    if (video.paused && !onPlayVideo) {
      onShowPlayButton?.(false);
      setHideBlur(true);
      await video.play();

      if (status === WatchedStatus.Unseen) {
        await changeVideoStatus?.();
      }
      return;
    }

    video.pause();
  };

  const onLoadedMetadataLocal = (): void => {
    setLoaded(true);
  };

  useEffect(() => {
    if (status === WatchedStatus.Watched) {
      setHideBlur(true);
    } else {
      setHideBlur(false);
    }
  }, [status]);

  return (
    <div>
      {children}
      <div
        className={`relative flex-center h-[60vh] bg-orange-100 md:h-150 md:bg-constants-white ${containerStyles} rounded-xl `}
      >
        <video
          ref={videoRef}
          src={src}
          onClick={onPlayVideo || toggleVideoPlayback}
          onPause={() => onShowPlayButton?.(true)}
          onEnded={() => onShowPlayButton?.(true)}
          className={`press-effect rounded-xl h-full ${blurEffect}`}
          onLoadedMetadata={onLoadedMetadata || onLoadedMetadataLocal}
        />
        {!loaded && (
          <Skeleton className={'absolute border w-full h-full rounded-xl'} />
        )}
        {invalidFormat && <InvalidFormatPlaceholder onClick={onPlayVideo} />}
        {showPlayButton && loaded && !invalidFormat && (
          <Icons.PlayCircle
            className={
              'press-effect absolute left-1/2 -translate-x-1/2 bottom-5 cursor-pointer'
            }
            onClick={onPlayVideo || toggleVideoPlayback}
          />
        )}
      </div>
    </div>
  );
});

VideoView.displayName = 'VideoView';
