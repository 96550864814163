import type { ReactElement } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import './styles.css';

import type { TabItemProps } from './types';

export const TabItem = (props: TabItemProps): ReactElement => {
  const { Icon, title, path } = props;

  const gap = path === Paths.Vote ? 'gap-1.5' : 'gap-2';
  const base = `flex-center flex-col ${gap} cursor-pointer select-none w-14`;
  const activeTab = 'active-tab-icon active-tab-title';

  const getLinkStyles: NavLinkProps['className'] = ({ isActive }) => {
    return isActive ? `${base} ${activeTab}` : `` + `${base}`;
  };

  return (
    <NavLink to={path} className={getLinkStyles}>
      <Icon />
      <span className={'text-sm text-gray-400'}>{title}</span>
    </NavLink>
  );
};
