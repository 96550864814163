import type { ReactElement } from 'react';

import { useTranslate } from 'hooks';

import { Images } from 'assets/images';

export const Copyright = (): ReactElement => {
  const { t } = useTranslate('tabBar');

  return (
    <div className={'flex md:inline-flex items-center gap-2.5'}>
      <Images.Logo className={'w-[33px] h-[33px] md:w-[63px] md:h-[63px]'} />
      <div className={'flex flex-col'}>
        <span className={'text-sm md:text-base'}>{t('projectTitle')}</span>
        <span className={'text-sm md:text-base'}>{t('rights')}</span>
      </div>
    </div>
  );
};
