import { toast } from 'react-toastify';

import { accountApi } from 'api/services/AccountService';

import { useAppStore } from '../AppStore';
import { parseErrors } from '../helpers/parseErrors';
import { serialiseUser } from '../helpers/serialiseUser';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { StatusText } from 'types/defaultTypes';
import type { IAccountStore } from './types';

export const useAccountStore = createWithEqualityFn<IAccountStore>()(
  devtools(
    persist(
      immer((set, getState) => ({
        user: null,
        isLoggedIn: false,
        signIn: async (payload) => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const data = await accountApi.signIn(payload);

            if (data) {
              set((state) => {
                state.user = serialiseUser(data.user);

                if (data.user.verified_at) {
                  state.isLoggedIn = true;
                }
              });

              localStorage.setItem('accessToken', data.token);
            }

            return {
              message: data.message,
              statusText: data.statusText,
              statusCode: data.statusCode,
              data: { verified: !!data.user.verified_at },
            };
          } catch (e) {
            return parseErrors(e);
          } finally {
            toggleLoader();
          }
        },
        signOut: () => {
          set((state) => {
            state.user = null;
            state.isLoggedIn = false;
          });
          localStorage.removeItem('accessToken');
        },
        registration: async (payload) => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const data = await accountApi.registration(payload);

            if (data) {
              set((state) => {
                state.user = data.user;
              });
              localStorage.setItem('accessToken', data.token);
            }

            return {
              message: data.message,
              statusText: data.statusText,
              statusCode: data.statusCode,
            };
          } catch (e) {
            return parseErrors(e);
          } finally {
            toggleLoader();
          }
        },
        resendVerify: async () => {
          const toggleLoader = useAppStore.getState().toggleLoader;

          try {
            toggleLoader();
            await accountApi.resendVerify();
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          } finally {
            toggleLoader();
          }
        },
        verifyEmail: async (payload) => {
          try {
            const response = await accountApi.verifyEmail(payload);

            if (response.statusText === StatusText.Ok) {
              const updatedUser = await accountApi.getCurrentUser();

              set((state) => {
                state.user = updatedUser;
                state.isLoggedIn = true;
              });
            }

            return response;
          } catch (e) {
            return parseErrors(e);
          }
        },
        getUser: async () => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const updatedUser = await accountApi.getCurrentUser();

            set((state) => {
              state.user = serialiseUser(updatedUser);
            });
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          } finally {
            toggleLoader();
          }
        },
        uploadVideo: async (data) => {
          try {
            const video = await accountApi.uploadVideo(data);

            set((state) => {
              if (!state.user) return;

              const parseCreatedDate = DateTime.fromFormat(
                video.created_at,
                'MM/dd/yyyy hh:mm:ss',
              ).toFormat('dd.MM.yyyy');

              state.user.video = { ...video, created_at: parseCreatedDate };
            });
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          }
        },
        deleteAccount: async () => {
          try {
            const status = await accountApi.deleteAccount();

            if (status === StatusText.Ok) {
              getState().signOut();
              toast.success(i18n.t('toasts.delete'), { autoClose: 2000 });
            }
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          }
        },
        updateProfile: async (formData) => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const updatedUser = await accountApi.updateProfile(formData);

            set((state) => {
              state.user = serialiseUser(updatedUser);
            });

            toast.success(i18n.t('toasts.updateProfile'));
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          } finally {
            toggleLoader();
          }
        },
        forgotPassword: async (payload) => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const message = await accountApi.forgotPassword(payload);
            toast.success(message, { autoClose: 2000 });
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.message);
          } finally {
            toggleLoader();
          }
        },
        resetPassword: async (payload) => {
          const toggleLoader = useAppStore.getState().toggleLoader;
          try {
            toggleLoader();
            const message = await accountApi.resetPassword(payload);
            toast.success(message, { autoClose: 2000 });
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.errors?.password[0]);
          } finally {
            toggleLoader();
          }
        },
        removeAvatar: async () => {
          try {
            const updatedUser = await accountApi.removeAvatar();

            set((state) => {
              state.user = serialiseUser(updatedUser);
            });

            toast.success(i18n.t('toasts.removeAvatar'));
          } catch (e) {
            const error = parseErrors(e);
            toast.error(error.errors?.password[0]);
          }
        },
      })),
      { name: 'accountStore' },
    ),
  ),
  shallow,
);
