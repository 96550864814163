import type { MouseEventHandler, ReactElement } from 'react';
import { useCallback } from 'react';

import { BaseModal } from '../BaseModal';

import type { OptionsModalProps } from './types';

export const OptionsModal = (props: OptionsModalProps): ReactElement | null => {
  const { isOpen = false, setModalOpen, options = [], className } = props;

  const onClose: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    setModalOpen?.(false);
  }, []);

  const onPress: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
  };

  const renderOptions = options.map((option, index) => {
    const border = index === options.length - 1 ? '' : 'border-b';

    return (
      <span
        key={option.title}
        onClick={option.action}
        className={`flex-center h-12 press-effect cursor-pointer ${border}`}
      >
        {option.title}
      </span>
    );
  });

  if (!isOpen) return null;

  return (
    <BaseModal
      className={'pb-0'}
      setIsOpen={setModalOpen}
      onClose={onClose}
      hideClose
    >
      <div className={`${className} flex flex-col w-64`} onClick={onPress}>
        {renderOptions}
      </div>
    </BaseModal>
  );
};
