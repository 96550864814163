import type { ReactElement } from 'react';

import { useTranslate } from 'hooks';

import { Timer, Timers, Typography } from 'components';

import type { ContestTimeInfoProps } from './types';

export const ContestTimeInfo = (props: ContestTimeInfoProps): ReactElement => {
  const { contestDate, timeLeft } = props;
  const { t } = useTranslate('profilePage');

  return (
    <div
      className={
        'flex flex-col border rounded-0.5xl p-5 gap-2.5 w-[20.9375rem]'
      }
    >
      <Typography title={t('contestDateTitle')} withContainer>
        <span className={'text-gray-600 font-normal text-sm'}>
          {contestDate}
        </span>
      </Typography>
      <Typography title={t('voteTimeTitle')} withContainer>
        <Timer timeLeft={timeLeft} type={Timers.Profile} />
      </Typography>
    </div>
  );
};
