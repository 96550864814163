import type { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export const Portal = (props: PropsWithChildren) => {
  const { children } = props;

  return createPortal(
    children,
    document.getElementById('root') ?? document.body,
  );
};
