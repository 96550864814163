import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { addZero, getTimerStyles } from './timer.utils';
import { Duration } from 'luxon';

import type { CountdownTimerProps } from './types';
import { Timers } from './types';

export const Timer = (props: CountdownTimerProps): ReactElement => {
  const { timeLeft, type = Timers.Contest, onTimeEnd } = props;
  const [timer, setTimer] = useState<number>(timeLeft ?? 0);
  const timeIsOver = timer <= 0;

  const { days, seconds, hours, minutes } = Duration.fromObject({
    seconds: timer,
  })
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();

  const { container, numbers } = getTimerStyles(type === Timers.Profile);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    if (timeIsOver && type === Timers.Profile) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timeIsOver && type === Timers.Contest) {
      onTimeEnd?.();
    }
  }, [timer]);

  return (
    <div className={`inline-flex ${container}`}>
      {type === Timers.Profile && (
        <div>
          <span>{days}</span>
          <span>{days === 1 ? ' day' : ' days'}</span>
        </div>
      )}
      <div className={numbers}>
        <span>{addZero(hours)}</span>
        <span>:</span>
        <span>{addZero(minutes)}</span>
        <span>:</span>
        <span>{addZero(seconds)}</span>
      </div>
    </div>
  );
};
