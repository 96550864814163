import type { ReactElement } from 'react';

import { getCurrentContestSelector, useContestStore } from 'store';

import { useTranslate } from 'hooks';

import { Jackpot } from '../Jackpot';
import { Timer } from 'components';

import type { HeaderProps } from './types';

export const Header = (props: HeaderProps): ReactElement => {
  const { t } = useTranslate('voteControlPanel');
  const { jackpot, timeLeft } = props;
  const getCurrentContest = useContestStore(getCurrentContestSelector);

  const onTimeEnd = (): void => {
    getCurrentContest();
  };

  return (
    <div className={'flex justify-between'}>
      <div className={'flex flex-col gap-5 mb-6'}>
        <Jackpot jackpot={jackpot} title={t('jackpot')} />
      </div>
      <div className={'flex flex-col items-end gap-3.5'}>
        <span
          className={
            'text-lg text-gray-700 font-medium tracking-tight leading-5'
          }
        >
          {t('timeToEndTitle')}
        </span>
        <Timer timeLeft={timeLeft} onTimeEnd={onTimeEnd} />
      </div>
    </div>
  );
};
