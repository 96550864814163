export enum Paths {
  Home = '/*',
  Upload = 'upload',
  Vote = 'vote',
  Profile = 'profile',
  Auth = '/account/*',
  SignIn = 'login',
  SignUp = 'register',
  Recovery = 'reset-password',
  RecoveryWithToken = 'reset-password/:token',
  Terms = 'terms',
  Verify = 'verify',
  About = 'about',
  BaseAuth = '/account',
  AnyOther = '*',
  ConfirmVerify = 'email/verify/:userId/:token/:expires/:signature',
  RedirectVerify = 'email/verify',
}

export enum AccountApiPath {
  Register = '/register',
  Login = '/login',
  Recovery = '/forgot-password',
  ResetPassword = '/reset-password',
  ResendVerify = '/email/send/verify',
  VerifyEmail = '/email/verify',
  GetCurrentUser = '/user',
  UploadVideo = '/videos',
  Users = '/users',
  Avatars = '/avatars',
  DeleteAccount = '/delete',
}

export enum ContestApiPath {
  Contests = '/contests',
  Previous = '/previous',
  Watched = '/watched',
  Votes = '/votes',
  ContestUnregistered = '/contests-unregistered',
  WatchedUnregistered = '/watched-unregistered',
  VotesUnregistered = '/votes-unregistered',
}
