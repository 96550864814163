import type { ITimerStyles } from './types';

export const addZero = (timeValue = 0): string => {
  return `${timeValue < 10 ? '0' : ''}${timeValue}`;
};

export const getTimerStyles = (contestType: boolean): ITimerStyles => {
  if (!contestType) {
    return {
      container: 'items-center bg-blue-100 rounded-full px-4 py-1 w-24 sm:w-30',
      numbers: 'text-sm sm:text-xl font-medium text-gray-100',
    };
  }

  return {
    container: 'gap-1 text-gray-600 font-normal leading-5 text-sm',
  };
};
