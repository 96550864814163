import { AccountApiPath, ContestApiPath } from 'common/constants/paths';

import {
  addAccessTokenInterceptor,
  removeRequestInterceptor,
} from 'api/helpers';
import { baseInstance } from 'api/instance';

import { useAppStore } from 'store';

import type {
  GetCurrentContestParams,
  IUnregisteredVoteDto,
  IWatchedInfo,
  SetWatchedStatusParams,
  VotingParams,
} from 'api/services/ContestService/types';
import type { IContest } from 'api/services/types';

export const contestApi = {
  async getCurrentContest(params: GetCurrentContestParams): Promise<IContest> {
    const { withoutAuth } = params;

    if (!withoutAuth) {
      const interceptorId = addAccessTokenInterceptor(baseInstance);
      const response = await baseInstance.get<IContest>(
        ContestApiPath.Contests,
      );
      removeRequestInterceptor(interceptorId, baseInstance);

      return response.data;
    } else {
      const fingerprint = useAppStore.getState().fingerprint;
      const response = await baseInstance.get<IContest>(
        `${ContestApiPath.ContestUnregistered}?device_id=${fingerprint}`,
      );

      return response.data;
    }
  },
  async getPreviousContest(): Promise<IContest> {
    const interceptorId = addAccessTokenInterceptor(baseInstance);
    const response = await baseInstance.get(
      `${ContestApiPath.Contests}${ContestApiPath.Previous}`,
    );

    removeRequestInterceptor(interceptorId, baseInstance);

    return response.data;
  },
  async setWatchStatus(params: SetWatchedStatusParams): Promise<IWatchedInfo> {
    const { videoId, withoutAuth } = params;

    if (!withoutAuth) {
      const interceptorId = addAccessTokenInterceptor(baseInstance);
      const response = await baseInstance.get<IWatchedInfo>(
        `${AccountApiPath.UploadVideo}/${videoId}${ContestApiPath.Watched}`,
      );

      removeRequestInterceptor(interceptorId, baseInstance);

      return response.data;
    } else {
      const fingerprint = useAppStore.getState().fingerprint;
      const response = await baseInstance.get<IWatchedInfo>(
        `${AccountApiPath.UploadVideo}/${videoId}${ContestApiPath.WatchedUnregistered}?device_id=${fingerprint}`,
      );

      return response.data;
    }
  },
  async voting(params: VotingParams): Promise<IWatchedInfo> {
    const { contestId, withoutAuth, videoId } = params;

    if (!withoutAuth) {
      const data = videoId ? { video_id: videoId } : undefined;
      const interceptorId = addAccessTokenInterceptor(baseInstance);
      const response = await baseInstance.post<IWatchedInfo>(
        `${ContestApiPath.Contests}/${contestId}${ContestApiPath.Votes}`,
        data,
      );

      removeRequestInterceptor(interceptorId, baseInstance);

      return response.data;
    } else {
      const fingerprint = useAppStore.getState().fingerprint;

      if (!fingerprint) {
        throw new Error('Unknown fingerprint');
      }

      const dto: IUnregisteredVoteDto = { device_id: fingerprint };

      if (videoId) {
        dto.video_id = videoId;
      }

      const response = await baseInstance.post<IWatchedInfo>(
        `${ContestApiPath.Contests}/${contestId}${ContestApiPath.VotesUnregistered}`,
        dto,
      );

      return response.data;
    }
  },
};
