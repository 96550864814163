import type { ReactElement } from 'react';
import { useEffect } from 'react';

import {
  getFingerprint,
  getIsLoading,
  getIsLoggedInSelector,
  useAccountStore,
  useAppStore,
  useContestStore,
  votePageSelector,
} from 'store';

import { useBreakpoint } from 'hooks';

import { ContestCandidate } from '../components';
import { ContestComingSoon } from './components/ContestComingSoon';
import { MinimisedContent } from './components/MinimisedContent';
import { VoteControlPanel } from './components/VoteControlPanel';
import { Loader } from 'components';

export const VotePage = (): ReactElement => {
  const { getCurrentContest, setWatchStatus, ...contest } =
    useContestStore(votePageSelector);
  const isLoggedIn = useAccountStore(getIsLoggedInSelector);
  const fingerprint = useAppStore(getFingerprint);
  const isLoading = useAppStore(getIsLoading);
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (!isLoggedIn && !fingerprint) return;
    getCurrentContest();
  }, [isLoggedIn, fingerprint]);

  if (isLoading && !contest.firstVideo && !contest.secondVideo) {
    return (
      <div className={'flex-center w-screen h-screen'}>
        <Loader size={'large'} colour={'secondary'} />
      </div>
    );
  }

  if (!contest.firstVideo || !contest.secondVideo) {
    return <ContestComingSoon isLoggedIn={isLoggedIn} />;
  }

  const { firstVideo, secondVideo } = contest;

  return (
    <div
      className={`h-[calc(100dvh-128px)] flex w-full justify-center mt-12 overflow-y-scroll`}
    >
      {breakpoint <= 976 ? (
        <MinimisedContent />
      ) : (
        <>
          <ContestCandidate
            video={firstVideo}
            setWatchStatus={setWatchStatus}
          />
          <VoteControlPanel isLoggedIn={isLoggedIn} />
          <ContestCandidate
            video={secondVideo}
            setWatchStatus={setWatchStatus}
          />
        </>
      )}
    </div>
  );
};
