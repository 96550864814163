import type { ReactElement } from 'react';

import { useTranslate } from 'hooks';

import { AccountPageContainer, SignUpForm } from '../components';

export const SignUpPage = (): ReactElement => {
  const { t } = useTranslate('signUpPage');

  return (
    <AccountPageContainer title={t('title')}>
      <SignUpForm />
    </AccountPageContainer>
  );
};
