import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Input } from '../Input';
import { Typography } from '../Typography';
import { Icons } from 'assets/icons';

import type { EditableTitleProps } from './types';

export const EditableTitle = (props: EditableTitleProps): ReactElement => {
  const { title, disableEditMode = false, children, ...restProps } = props;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onEditClick = useCallback((): void => {
    !disableEditMode && setIsEditMode((prev) => !prev);
  }, [disableEditMode]);

  const closeStyle: string = children
    ? 'top-2 translate-y-1/2'
    : 'bottom-2 -translate-y-1/2';

  useEffect(() => {
    const handleEvent = (): void => {
      setIsEditMode(false);
    };

    document.addEventListener('closeFields', handleEvent);

    return () => {
      document.removeEventListener('closeFields', handleEvent);
    };
  }, []);

  if (isEditMode) {
    return (
      <div className={'flex relative'}>
        {!children ? (
          <Input
            placeholder={title}
            className={'text-xl leading-6.5 p-0 h-12 px-4 w-52 sm:w-56'}
            {...restProps}
          />
        ) : (
          children
        )}
        <Icons.Close
          className={
            `absolute cursor-pointer left-[13.5rem] usm:left-60 transform ${closeStyle} ` +
            'w-4 h-4'
          }
          onClick={onEditClick}
        />
      </div>
    );
  }

  return (
    <Typography
      title={(restProps.value as string) || title}
      className={
        'border w-56 border-constants-white flex justify-between items-center gap-2 '
      }
      textStyle={'whitespace-nowrap text-ellipsis overflow-hidden'}
      withContainer
    >
      {!disableEditMode && (
        <div>
          <Icons.Pen
            className={'cursor-pointer text-gray-400'}
            onClick={onEditClick}
          />
        </div>
      )}
    </Typography>
  );
};
