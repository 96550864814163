import type { ReactElement } from 'react';
import { useMemo } from 'react';

import { Paths } from 'common/constants/paths';

import { getIsLoggedInSelector, useAccountStore } from 'store';

import { useBreakpoint, useTranslate } from 'hooks';

import { Copyright } from './Copyright';
import { Privacy } from './Privacy';
import { TabItem } from './TabItem';
import { Icons } from 'assets/icons';

import type { TabItemProps } from './TabItem/types';

export const TabBar = (): ReactElement => {
  const { t } = useTranslate('tabBar');
  const isLoggedIn = useAccountStore(getIsLoggedInSelector);
  const { breakpoint } = useBreakpoint();

  const tabItems: TabItemProps[] = useMemo(
    () => [
      {
        Icon: Icons.Plus,
        title: t('upload'),
        path: Paths.Upload,
      },
      {
        Icon: Icons.Weight,
        title: t('vote'),
        path: Paths.Vote,
      },
      {
        Icon: Icons.Profile,
        title: t('profile'),
        path: Paths.Profile,
      },
      // {
      //   Icon: Icons.About,
      //   title: t('about'),
      //   path: Paths.About,
      // },
    ],
    [t],
  );

  const showPrivacy = breakpoint > 480 || (!isLoggedIn && breakpoint <= 480);

  return (
    <div
      className={
        'h-14 rounded-t-2xl pl-2 pr-2 ' +
        'md:h-17.5 md:pl-4 md:pr-4 md:rounded-t-none ' +
        'lg:pl-19 lg:pr-20 ' +
        'fixed bottom-0 w-full py-1 bg-tabBackground ' +
        'border-t border-constants-white shadow-bottomTab'
      }
    >
      <div className={'flex w-full justify-between items-center'}>
        {showPrivacy && <Copyright />}
        {isLoggedIn && (
          <nav
            className={
              'flex w-full justify-around ' +
              'md:w-fit md:gap-8 md:mr-20 lg:mr-35 lg:gap-15'
            }
          >
            {tabItems.map((link) => (
              <TabItem key={link.path} {...link} />
            ))}
          </nav>
        )}
        {showPrivacy && <Privacy />}
      </div>
    </div>
  );
};
