import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { controlPanelSelector, useContestStore } from 'store';

import { useTranslate } from 'hooks';

import { ButtonsPanel } from '../ButtonsPanel';
import { Header } from '../Header';
import { SignUpBox } from '../SignUpBox';
import { Images } from 'assets/images';

import type { VoteControlPanelProps } from './types';
import { VoteStatus } from 'api/services/types';

export const VoteControlPanel = (
  props: VoteControlPanelProps,
): ReactElement => {
  const { t } = useTranslate('toasts');
  const { isLoggedIn } = props;
  const { isVoted, jackpot, votingIsEnable, voting, timeLeft } =
    useContestStore(controlPanelSelector);

  const voteForVideo = useCallback(
    (videoNumber?: 1 | 2) => async (): Promise<void> => {
      if (!votingIsEnable) {
        toast.info(t('bothWaring'));
        return;
      }

      if (videoNumber) {
        await voting(videoNumber);
        return;
      }

      await voting();
    },
    [votingIsEnable],
  );

  return (
    <div className={'mx-14'}>
      <Header jackpot={jackpot} timeLeft={timeLeft} />
      <div
        className={
          'pt-5.5 pb-8 w-84 ' +
          'flex-center flex-col border-3 rounded-lg border-orange-300'
        }
      >
        <Images.LogoWithTitle />
        <ButtonsPanel
          voteForVideo={voteForVideo}
          isVoted={isVoted === VoteStatus.Voted}
          disableButtons={!votingIsEnable}
        />
      </div>
      {!isLoggedIn && <SignUpBox />}
    </div>
  );
};
