import type { ReactElement } from 'react';
import { memo } from 'react';

import styles from './Checkbox.module.css';

import type { CheckboxProps } from './types';

export const Checkbox = memo((props: CheckboxProps): ReactElement => {
  const { onClick, ...restProps } = props;

  return (
    <div className={'relative'}>
      <input {...restProps} type={'checkbox'} className={styles.input} />
      <div className={styles.checkbox} onClick={onClick} />
    </div>
  );
});

Checkbox.displayName = 'CheckBox';
