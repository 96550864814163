import type { IResponse } from 'types/defaultTypes';
import { StatusText } from 'types/defaultTypes';

export interface IError {
  response?: {
    data?: {
      message: string;
      errors?: IResponse['errors'];
    };
    status: number;
    statusText: StatusText;
  };
}

export const parseErrors = (error: unknown): IResponse => {
  const baseError = error as IError;
  const response = baseError.response;

  if (response && response.data?.message && response.statusText) {
    const { data, statusText, status } = response;

    return {
      message: data.message,
      statusText,
      statusCode: status,
      errors: data?.errors || null,
    };
  }

  if (!navigator.onLine) {
    return {
      message: 'Network error',
      statusCode: -1,
      statusText: StatusText.NetworkError,
    };
  }

  return {
    message: 'Unknown error',
    statusCode: -1,
    statusText: StatusText.UnknownError,
  };
};
