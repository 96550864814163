import { initReactI18next } from 'react-i18next';

import en from 'common/translations/en.json';

import i18n from 'i18next';

const resources = {
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  resources,
  interpolation: { escapeValue: false },
  returnNull: false,
});

export default i18n;
