import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { useTranslate } from 'hooks';

import { useSignInForm } from '../../hooks/useSignInForm';
import {
  BaseButton,
  BaseButtonTemplates,
  Input,
  Loader,
  TextButton,
} from 'components';

export const SignInForm = (): ReactElement => {
  const { t } = useTranslate('signInPage');
  const navigate = useNavigate();

  const { handleSubmit, formConfig, disabledSubmit, setFieldValue, isLoading } =
    useSignInForm();

  const onClickForgot = useCallback((): void => {
    navigate(`/${Paths.Recovery}`);
  }, [navigate]);

  const onClickSignUp = useCallback((): void => {
    navigate(`${Paths.BaseAuth}/${Paths.SignUp}`);
  }, [navigate]);

  const renderFields = formConfig.map((field) => (
    <Input
      key={field.name}
      {...field}
      placeholder={t(`${field.name}Placeholder`)}
    />
  ));

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={'flex-center flex-col gap-4'}>
          <div className={'flex-center flex-col gap-4'}>{renderFields}</div>
          <TextButton title={t('forgot')} onClick={onClickForgot} />
          <BaseButton
            type={'submit'}
            template={BaseButtonTemplates.Fill}
            className={'w-full'}
            disabled={disabledSubmit}
          >
            {isLoading ? <Loader /> : t('login')}
          </BaseButton>
        </div>
      </form>
      <TextButton title={t('signup')} onClick={onClickSignUp} />
      {process.env.REACT_APP_MODE === 'dev' && (
        <TextButton
          title={'Test account'}
          onClick={() => {
            setFieldValue('email', 'kelekofficial@gmail.com');
            setFieldValue('password', 'Zxcasdqwe123');
          }}
        />
      )}
    </>
  );
};
