import type { ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { getIsLoading, useAppStore } from 'store';

import {
  Avatar,
  BaseButton,
  BaseModal,
  EditableTitle,
  Input,
  Loader,
  TextButton,
} from 'components';
import { useFormik } from 'formik';
import i18n from 'i18next';
import { isBase64 } from 'utils/isBase64';
import { triggerCustomEvent } from 'utils/triggerCustomEvent';
import { object, ref, string } from 'yup';

import type { IProfileForm, UserDataProps } from './types';

const validationSchema = object().shape({
  name: string()
    .min(6, i18n.t('validation.minNameLength'))
    .max(30, i18n.t('validation.maxNameLength'))
    .matches(
      /^[a-zA-Z0-9_.-]+( [a-zA-Z0-9_.-]+)?$/,
      i18n.t('validation.namePatterns'),
    )
    .required(i18n.t('validation.requiredName')),
  password: string()
    .min(8, i18n.t('validation.passwordMin'))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
      i18n.t('validation.passwordPatterns'),
    ),
  newPassword: string().when('password', {
    is: (val: string) => val && val.length > 0,
    then: (schema) =>
      schema
        .min(8, i18n.t('validation.passwordMin'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
          i18n.t('validation.passwordPatterns'),
        )
        .required(i18n.t('validation.requiredPassword')),
  }),
  confirmPassword: string().when('newPassword', {
    is: (val: string) => val && val.length > 0,
    then: (schema) =>
      schema.oneOf([ref('newPassword')], i18n.t('validation.confirmPassword')),
  }),
});

export const UserData = (props: UserDataProps): ReactElement => {
  const { user, saveTitle, updateProfile, removeAvatar } = props;
  const isLoading = useAppStore(getIsLoading);
  const [preview, setPreview] = useState<File | null>(null);
  const pickerRef = useRef<AvatarEditor | null>(null);

  const {
    dirty,
    setFieldValue,
    getFieldProps,
    errors,
    touched,
    handleSubmit,
    values,
  } = useFormik<IProfileForm>({
    initialValues: {
      avatar: user.avatar ?? '',
      name: user.name,
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values, formikHelpers) => {
      const { avatar, confirmPassword, newPassword, password, name } = values;
      const formData = new FormData();
      triggerCustomEvent({ type: 'closeFields' });

      if (avatar !== user.avatar) {
        formData.append('avatar', avatar);
      }

      formData.append('name', name);
      formData.append('password', newPassword);
      formData.append('password_confirmation', confirmPassword);
      formData.append('current_password', password);

      await updateProfile(formData);
      formikHelpers.resetForm();
    },
    validationSchema,
    enableReinitialize: true,
  });

  const onChangeValue = useCallback(
    (field: keyof IProfileForm) => async (value: File | null) => {
      if (value !== null) {
        setPreview(value);
        return;
      }

      if (user.avatar) {
        await removeAvatar();
        return;
      }

      setFieldValue(field, value);
    },
    [],
  );

  const applyAvatar = useCallback(() => {
    const photo = pickerRef.current
      ?.getImageScaledToCanvas()
      .toDataURL('image/png');

    if (photo) {
      const photoBase64 = photo.split(',')[1];

      if (isBase64(photoBase64)) {
        const byteCharacters = atob(photoBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new File([byteArray], 'avatar', { type: 'image/png' });

        setFieldValue('avatar', file);
      }

      setPreview(null);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className={'flex gap-5.5 mb-3.5'}>
        <Avatar
          avatar={values.avatar}
          size={'large'}
          onChangeValue={onChangeValue('avatar')}
        />
        <div className={'flex flex-1 flex-col gap-1.5'}>
          <EditableTitle title={user.email} disableEditMode />
          <EditableTitle
            title={user.name}
            hasError={!!errors.name && touched.name}
            errorText={errors.name}
            {...getFieldProps('name')}
          />
          <EditableTitle title={'Password'} disabled>
            <div className={'flex flex-col gap-2'}>
              <Input
                type={'password'}
                placeholder={'Current password'}
                hasError={!!errors.password && touched.password}
                errorText={errors.password}
                {...getFieldProps('password')}
                className={
                  'placeholder:text-base text-xl leading-6.5 p-0 h-12 px-4 w-52 sm:w-56'
                }
              />
              <Input
                type={'password'}
                placeholder={'New password'}
                hasError={!!errors.newPassword && touched.newPassword}
                errorText={errors.newPassword}
                {...getFieldProps('newPassword')}
                className={
                  'placeholder:text-base text-xl leading-6.5 p-0 h-12 px-4 w-52 sm:w-56'
                }
              />
              <Input
                type={'password'}
                placeholder={'Confirm password'}
                hasError={!!errors.confirmPassword && touched.confirmPassword}
                errorText={errors.confirmPassword}
                {...getFieldProps('confirmPassword')}
                className={
                  'placeholder:text-base text-xl leading-6.5 p-0 h-12 px-4 w-52 sm:w-56'
                }
              />
            </div>
          </EditableTitle>
        </div>
      </div>
      <BaseButton
        type={'submit'}
        className={'w-full'}
        disabled={!dirty || Object.keys(errors).length !== 0}
      >
        {isLoading ? <Loader /> : saveTitle}
      </BaseButton>
      {preview && (
        <BaseModal onClose={() => setPreview(null)}>
          <div
            className={'flex flex-col items-center'}
            onClick={(e) => e.stopPropagation()}
          >
            <AvatarEditor
              ref={pickerRef}
              image={preview}
              crossOrigin='anonymous'
              width={280}
              height={280}
              border={0}
              borderRadius={1000}
              scale={1}
              rotate={0}
            />
            <BaseButton className={'w-full my-4'} onClick={applyAvatar}>
              {'Apply'}
            </BaseButton>
            <TextButton title={'Cancel'} onClick={() => setPreview(null)} />
          </div>
        </BaseModal>
      )}
    </form>
  );
};
