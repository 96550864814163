import type { ReactElement } from 'react';
import { memo } from 'react';

import type { JackpotProps } from './types';

export const Jackpot = memo((props: JackpotProps): ReactElement => {
  const { jackpot, title, minMode = false } = props;

  const amountTitle = minMode ? `$${jackpot}!` : `$${jackpot}`;
  const amountStyles = minMode ? 'text-lg' : 'text-5xl';

  const titleStyle = minMode ? 'text-base' : 'text-lg';

  const containerStyle = minMode
    ? 'flex-row items-center gap-2 mt-4 mb-4.5'
    : 'flex-col gap-5';

  return (
    <div className={`flex ${containerStyle}`}>
      <span
        className={`${titleStyle} text-gray-700 font-medium tracking-tight leading-5`}
      >
        {title}
      </span>
      <span
        className={`${amountStyles} font-bold tracking-tight text-orange-500 leading-8`}
      >
        {amountTitle}
      </span>
    </div>
  );
});

Jackpot.displayName = 'Jackpot';
