import { toast } from 'react-toastify';

import { contestApi } from 'api/services/ContestService';

import { useAccountStore } from '../AccountStore';
import { useAppStore } from '../AppStore';
import { parseErrors } from '../helpers/parseErrors';
import i18n from 'i18next';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import type { IContestStore } from './types';
import type { VotingParams } from 'api/services/ContestService/types';
import type { IContest } from 'api/services/types';
import { VoteStatus } from 'api/services/types';

export const useContestStore = createWithEqualityFn<IContestStore>()(
  devtools(
    immer((set, getState) => ({
      contest: null,
      votingIsEnable: false,
      getCurrentContest: async () => {
        const toggleLoader = useAppStore.getState().toggleLoader;
        try {
          toggleLoader();
          const isLoggedIn = useAccountStore.getState().isLoggedIn;

          const contest = await contestApi.getCurrentContest({
            withoutAuth: !isLoggedIn,
          });

          if (contest?.id) {
            set((state) => {
              state.contest = contest;
              state.votingIsEnable =
                !!contest.video_1.watched && !!contest.video_2.watched;
            });
          }
        } catch (e) {
          const error = parseErrors(e);

          if (error.message !== 'Contest not found') {
            toast.error(error.message);
          }
        } finally {
          toggleLoader();
        }
      },
      getPreviousContest: async () => {
        try {
          const contest = await contestApi.getPreviousContest();

          return {
            video1: contest.video_1.votes ?? 0,
            video2: contest.video_2.votes ?? 0,
            push: contest.push ?? 0,
          };
        } catch (e) {
          const error = parseErrors(e);
          process.env.REACT_APP_PREVIOUS_CONTEST_DISABLE_TOAST &&
            toast.error(error.message);
        }
      },
      setWatchStatus: async (videoId) => {
        const isLoggedIn = useAccountStore.getState().isLoggedIn;
        try {
          const { watched_1, watched_2 } = await contestApi.setWatchStatus({
            videoId,
            withoutAuth: !isLoggedIn,
          });

          const { getCurrentContest } = getState();
          await getCurrentContest();

          if (watched_1 && watched_2) {
            set((state) => {
              state.votingIsEnable = true;
            });
          }
        } catch (e) {
          const error = parseErrors(e);
          toast.error(error.message);
        }
      },
      voting: async (videoNumber) => {
        const { contest } = getState();
        if (!contest) return;

        const isLoggedIn = useAccountStore.getState().isLoggedIn;
        const fieldKey = `video_${videoNumber}` as keyof Pick<
          IContest,
          'video_1' | 'video_2'
        >;

        const basePayload: VotingParams = {
          withoutAuth: !isLoggedIn,
          contestId: contest.id,
        };

        try {
          if (videoNumber) {
            await contestApi.voting({
              ...basePayload,
              videoId: contest[fieldKey].id,
            });
          } else {
            await contestApi.voting(basePayload);
          }

          set((state) => {
            if (!state.contest) return;
            state.contest.voted = VoteStatus.Voted;
          });

          toast.success(i18n.t('toasts.counted'), { autoClose: 2000 });
        } catch (e) {
          const error = parseErrors(e);
          toast.error(error.message);
        }
      },
    })),
  ),
  shallow,
);
