import { VideoStatuses } from 'api/services/types';

export const toUppercaseFirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const parseStatus = (status: VideoStatuses): string => {
  switch (status) {
    case VideoStatuses.Disapproved:
    case VideoStatuses.Uploaded: {
      return toUppercaseFirst(status);
    }
    case VideoStatuses.InTheContest:
    case VideoStatuses.InThePool: {
      return toUppercaseFirst(status.replaceAll('_', ' '));
    }
  }
};
