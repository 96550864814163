import type { ReactElement, SVGProps } from 'react';

import type { HttpStatusCode } from 'axios';

export type IconType = (props: SVGProps<SVGElement>) => ReactElement;

export enum StatusText {
  Unauthorized = 'Unauthorized',
  UnknownError = 'UnknownError',
  Ok = 'OK',
  NetworkError = 'NetworkError',
}

export interface IResponse<DataType = undefined> {
  message: string;
  statusText: StatusText;
  statusCode: HttpStatusCode | -1;

  errors?: Record<string, string[]> | null;
  data?: DataType;
}

export enum OS {
  Ios = 'ios',
  Android = 'android',
  Web = 'web',
}
