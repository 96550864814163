import type { ReactElement } from 'react';
import { memo } from 'react';

import { PRESS_EFFECT } from 'common/constants/styles';

import type { TextButtonProps } from './types';

export const TextButton = memo((props: TextButtonProps): ReactElement => {
  const { title, onClick, className } = props;

  return (
    <div className={`w-fit select-none ${className ?? ''}`} onClick={onClick}>
      <span
        className={
          `${PRESS_EFFECT} text-gray-600 font-medium text-sm ` +
          'leading-4 cursor-pointer '
        }
      >
        {title}
      </span>
    </div>
  );
});

TextButton.displayName = 'TextButton';
