import { ReactComponent as EmptyAvatar } from './emptyAvatar.svg';
import { ReactComponent as Lines } from './lines.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoWithTitle } from './logoWithTitle.svg';
import { ReactComponent as Name } from './name.svg';

export const Images = {
  LogoWithTitle,
  Logo,
  EmptyAvatar,
  Lines,
  Name,
};
