export interface ITimerStyles {
  container: string;

  numbers?: string;
}

export enum Timers {
  Contest = 'contest',
  Profile = 'profile',
}

export interface CountdownTimerProps {
  type?: Timers;
  timeLeft?: number;
  onTimeEnd?: () => void;
}
