import type { DependencyList, RefObject } from 'react';
import { useEffect } from 'react';

interface UseCreateThumbnailParams {
  ref: RefObject<HTMLVideoElement>;

  onSuccess?: (image: File, orientation: Orientations) => void;
  fileName?: string;
  type?: string;
}

export enum Orientations {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export const useCreateThumbnail = (
  params: UseCreateThumbnailParams,
  deps?: DependencyList,
) => {
  const { ref, fileName, type, onSuccess } = params;

  useEffect(() => {
    setTimeout(() => {
      const videoElement = ref.current;
      if (!videoElement) return;

      const { videoWidth, videoHeight } = videoElement;

      const canvas = document.createElement('canvas');

      canvas.width = videoWidth;
      canvas.height = videoHeight;
      canvas
        .getContext('2d')
        ?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (!blob) return;

        const file = new File([blob], fileName ?? 'first-frame.png', {
          type: type ?? 'image/png',
        });

        const orientation =
          videoWidth > videoHeight
            ? Orientations.Landscape
            : Orientations.Portrait;

        onSuccess?.(file, orientation);
      });
    }, 500);
  }, deps ?? []);
};
