import type { ReactElement } from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import { accountRedirectSelector, useAccountStore } from 'store';

import { useIsPage } from 'hooks';

export const AccountRedirect = (): ReactElement => {
  const navigate = useNavigate();
  const isAccountPage = useIsPage(Paths.BaseAuth);
  const { isVerified, isLoggedIn } = useAccountStore(accountRedirectSelector);

  const handleRedirect = useCallback((): void => {
    if (!isLoggedIn && !isAccountPage) {
      navigate(`${Paths.BaseAuth}/${Paths.SignIn}`);
      return;
    }

    if (isLoggedIn && isVerified) {
      navigate(`/${Paths.Vote}`);
      return;
    }

    if (isLoggedIn && !isVerified) {
      navigate(`${Paths.BaseAuth}/${Paths.Verify}`);
    }
  }, [navigate, isLoggedIn, isVerified]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return <Outlet />;
};
