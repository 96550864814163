import type { ReactElement } from 'react';
import { memo } from 'react';

import { Icons } from 'assets/icons';

import type { UploadVideoCardProps } from './types';

export const UploadVideoCard = memo(
  (props: UploadVideoCardProps): ReactElement => {
    const { onClickAddVideo, uploadPlaceholder } = props;

    return (
      <div className={'sm:flex-center w-full px-8'}>
        <div
          className={
            'flex-center press-effect bg-orange-100 h-55.5 ' +
            'rounded-xl border border-gray-800 cursor-pointer ' +
            'sm:w-100 '
          }
        >
          <div
            className={'flex-center flex-col gap-5'}
            onClick={onClickAddVideo}
          >
            <Icons.Upload
              className={'w-[85px] h-[62px] usm:w-[100px] usm:h-[74px]'}
            />
            <span
              className={
                'block w-60 usm:w-64 text-xl usm:text-2xl text-gray-700 font-bold text-center select-none'
              }
            >
              {uploadPlaceholder}
            </span>
          </div>
        </div>
      </div>
    );
  },
);

UploadVideoCard.displayName = 'UploadVideoCard';
