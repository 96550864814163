import type { ReactElement } from 'react';

import { useTranslate } from 'hooks';

import { AccountPageContainer, SignInForm } from '../components';

export const SignInPage = (): ReactElement => {
  const { t } = useTranslate('signInPage');

  return (
    <AccountPageContainer title={t('title')}>
      <SignInForm />
    </AccountPageContainer>
  );
};
