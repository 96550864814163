import type { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

import { useBreakpoint, useIsPage } from 'hooks';

import { Images } from 'assets/images';

import type { ContainerWithLogoProps } from './types';

export const ContainerWithLogo = (
  props: ContainerWithLogoProps,
): ReactElement => {
  const { children } = props;
  const { breakpoint } = useBreakpoint();
  const isAccountPage = useIsPage(Paths.BaseAuth);
  const navigate = useNavigate();

  const heightOfContainer =
    breakpoint >= 480 && isAccountPage
      ? 'md:h-[calc(100dvh-80px)]'
      : 'h-[calc(100dvh-80px)]';

  const logoStyles = isAccountPage ? 'cursor-pointer press-effect' : '';

  const onClickLogo = (): void => {
    navigate(`/${Paths.Vote}`);
  };

  return (
    <div
      className={
        'flex justify-center w-screen pt-4 mb-17.5 overflow-y-scroll overflow-x-hidden ' +
        `md:pt-17.5 ${heightOfContainer}`
      }
    >
      <div className={`w-screen flex justify-start items-center flex-col`}>
        <div
          className={`w-32 h-32 sm:w-55 sm:h-55 ${logoStyles}`}
          onClick={onClickLogo}
        >
          <Images.LogoWithTitle className={'w-32 h-32 sm:w-55 sm:h-55 mb-4'} />
        </div>
        {children}
      </div>
    </div>
  );
};
