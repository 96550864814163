import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Paths } from 'common/constants/paths';

import {
  getIsLoading,
  signInSelector,
  useAccountStore,
  useAppStore,
} from 'store';

import { useTranslate } from 'hooks';

import type { InputProps } from 'components';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import type { ISignInValues, UseAccountFormReturn } from './types';

export const useSignInForm = (): UseAccountFormReturn => {
  const { t } = useTranslate('validation');
  const { signIn } = useAccountStore(signInSelector);
  const isLoading = useAppStore(getIsLoading);
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      object({
        email: string().email(t('emailError')).required(t('requiredEmail')),
        password: string()
          .min(8, t('passwordMin'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
            t('passwordPatterns'),
          )
          .required(t('requiredPassword')),
      }),
    [t],
  );

  const { handleSubmit, getFieldProps, errors, touched, setFieldValue, dirty } =
    useFormik<ISignInValues>({
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: async (values) => {
        const res = await signIn(values);

        if (res.statusCode > 400 || res.statusCode === -1) {
          toast.error(res.message);
          return;
        }

        if (res.data?.verified) {
          navigate(`/${Paths.Vote}`);
        } else {
          navigate(`${Paths.BaseAuth}/${Paths.Verify}`);
        }

        toast.success(res.message, { autoClose: 2000 });
      },
      validationSchema,
    });

  const formConfig: InputProps[] = [
    {
      ...getFieldProps('email'),
      type: 'text',
      hasError: !!errors.email && touched.email,
      errorText: errors.email,
    },
    {
      ...getFieldProps('password'),
      type: 'password',
      hasError: !!errors.password && touched.password,
      errorText: errors.password,
    },
  ];

  return {
    formConfig,
    handleSubmit,
    isLoading,
    disabledSubmit: Object.keys(errors).length !== 0 || !dirty,
    setFieldValue,
  };
};
