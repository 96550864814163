import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Paths } from 'common/constants/paths';

import { useAccountStore, verifyEmailSelector } from 'store';

import { useMobileRedirect } from 'hooks';

import { getOS } from 'utils/mobileRedirect';

import { OS, StatusText } from 'types/defaultTypes';
import type { VerifyParams } from './types';

export const ConfirmVerify = (): null => {
  const params = useParams<Record<VerifyParams, string>>();
  const navigate = useNavigate();
  const verifyEmail = useAccountStore(verifyEmailSelector);

  useMobileRedirect({
    verify: {
      userId: params.userId ?? '',
      token: params.token ?? '',
      expires: params.expires ?? '',
      signature: params.signature ?? '',
    },
  });

  const verifyEmailHandler = useCallback(async () => {
    const { userId, token, signature, expires } = params;

    if (expires && signature && userId && token) {
      const { message, statusText } = await verifyEmail({
        expires,
        signature,
        userId,
        token,
      });

      if (statusText === StatusText.Ok) {
        toast.success(message, { autoClose: 2000 });
        navigate(`/${Paths.Vote}`);
        return;
      }

      toast.error(message);
    }
  }, [params, verifyEmail, navigate]);

  useEffect(() => {
    if (getOS() === OS.Web) {
      verifyEmailHandler();
    }
  }, [verifyEmailHandler]);

  return null;
};
