import type { ReactElement } from 'react';
import { animated, useSpring } from 'react-spring';

import type { ColumnProps } from './types';

export const Column = (props: ColumnProps): ReactElement => {
  const {
    title,
    votes,
    height = 1,
    baseDuration = 10,
    startAnimation = false,
  } = props;
  const parseHeight = isNaN(height) ? 1 : height === 0 ? 1 : height;

  const columnAnimation = useSpring({
    height: startAnimation ? parseHeight + '%' : '1%',
    overflow: 'hidden',
    config: {
      duration: baseDuration * height,
    },
  });

  return (
    <div className={'flex flex-col justify-end items-center h-full '}>
      <span className={'text-constants-white'}>{votes}</span>
      <animated.div style={columnAnimation} className={`column-gradient w-8`} />
      <span className={'text-constants-white mt-2 text-sm'}>{title}</span>
    </div>
  );
};
