import type { MouseEventHandler, ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';

import { getLayoutSelector, useAccountStore } from 'store';

import { VideoView } from '../VideoView';
import { Avatar } from 'components';

import type { ContestCandidateProps } from './types';

export const ContestCandidate = (
  props: ContestCandidateProps,
): ReactElement => {
  const { video, setWatchStatus, disableBlur = false } = props;
  const { title, description, player, url, watched, id } = video;
  const videoRef = useRef<HTMLVideoElement>(null);
  const { user } = useAccountStore(getLayoutSelector);

  const [showPlayButton, setShowPlayButton] = useState<boolean>(true);

  const changeVideoStatus = useCallback(async () => {
    await setWatchStatus?.(id);
  }, []);

  const onClickContainerView: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={onClickContainerView}>
      <VideoView
        changeVideoStatus={changeVideoStatus}
        src={url}
        videoRef={videoRef}
        status={watched}
        showPlayButton={showPlayButton}
        onShowPlayButton={setShowPlayButton}
        disableBlur={disableBlur}
      />
      <div className={'flex items-center gap-3.5 mt-9 mb-4'}>
        <Avatar avatar={player?.avatar ?? user?.avatar ?? ''} />
        <span className={'text-2xl font-semibold text-gray-700 leading-6.5'}>
          {player?.name ?? user?.name}
        </span>
      </div>
      <div className={'flex flex-col w-84 gap-1.5'}>
        <span className={'text-xl text-gray-700 leading-6.5 font-medium '}>
          {title}
        </span>
        <p className={'leading-5 text-gray-600 opacity-80'}>
          Description: {description}
        </p>
      </div>
    </div>
  );
};
