import type { ButtonHTMLAttributes, ReactNode } from 'react';

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  template?: BaseButtonTemplates;
  colour?: 'bg-orange-300' | 'bg-constants-white';
  textColour?:
    | 'text-orange-300'
    | 'text-constants-white'
    | 'text-constants-black';
  maxSizeEnable?: boolean;
  addDisableStyle?: boolean;
}

export enum BaseButtonTemplates {
  Fill = 'fill',
  Empty = 'empty',
}
