import { ReactComponent as About } from './about.svg';
import { ReactComponent as AvatarGirl } from './avatarGirl.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Chevron } from './chevron.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as EmptyImage } from './emptyImage.svg';
import { ReactComponent as InvalidFormat } from './invalidFormat.svg';
import { ReactComponent as LeftArrow } from './leftArrow.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as Pen } from './pen.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlayCircle } from './playCircle.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as RightArrow } from './rightArrow.svg';
import { ReactComponent as SecurityClose } from './securityClose.svg';
import { ReactComponent as SecurityOpen } from './securityOpen.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Weight } from './weight.svg';

export const Icons = {
  Check,
  SecurityOpen,
  About,
  Weight,
  Play,
  Profile,
  Upload,
  Plus,
  Pen,
  SecurityClose,
  Chevron,
  LeftArrow,
  RightArrow,
  AvatarGirl,
  PlayCircle,
  Close,
  Message,
  Question,
  EmptyImage,
  InvalidFormat,
};
