import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Paths } from 'common/constants/paths';

export const useVerifyRedirect = (): void => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes(Paths.RedirectVerify)) {
      const newURL = window.location.href
        .replace(process.env.REACT_APP_BASE_URL, '')
        .replace('?expires=', '/')
        .replace('&signature=', '/');

      navigate(newURL);
    }
  }, []);
};
