import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';

import { useContestStore } from 'store';

import { useTranslate } from 'hooks';

import { Icons } from 'assets/icons';
import { BaseButton, Skeleton, VideoModal } from 'components';

import type { MinimisedVideoProps } from './types';

export const MinimisedVideo = (props: MinimisedVideoProps): ReactElement => {
  const { video, onVote, first = false, isVoted, disabled = true } = props;
  const { t } = useTranslate('voteControlPanel');
  const setVideoStatus = useContestStore((state) => state.setWatchStatus);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const buttonIcon = first ? <Icons.LeftArrow /> : <Icons.RightArrow />;
  const buttonStyles = first ? 'flex-row-reverse' : 'flex-row';
  const blurEffect: string = video.watched ? '' : 'opacity-50';

  const onOpenModel = useCallback((): void => {
    setIsOpen(true);
  }, []);

  return (
    <div className={'flex flex-col gap-4 overflow-hidden'}>
      <div className={'relative press-effect'} onClick={onOpenModel}>
        {!loaded && (
          <Skeleton className={'absolute border w-full h-full rounded-xl'} />
        )}
        <img
          className={`rounded min-w-[157px] min-h-[285px] w-[40vw] h-[calc(40vw*1.81)] max-w-[276px] max-h-[500px] ${blurEffect}`}
          src={video.thumbnail}
          alt={'video'}
          onLoad={() => setLoaded(true)}
        />
        <Icons.PlayCircle
          className={
            'absolute left-1/2 -translate-x-1/2 bottom-2 w-[22px] h-[22px]'
          }
        />
      </div>
      {!isVoted && (
        <BaseButton
          className={`${buttonStyles} gap-2.5 w-full h-13 px-0 py-0`}
          onClick={onVote}
          addDisableStyle={disabled}
        >
          <span>{t('vote')}</span>
          {buttonIcon}
        </BaseButton>
      )}
      <VideoModal
        video={video}
        isOpen={isOpen}
        setModalOpen={setIsOpen}
        setVideoStatus={setVideoStatus}
      />
    </div>
  );
};
