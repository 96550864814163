interface UseCustomEventPayloadType<TPayload = unknown> {
  type: string;
  eventInitDict?: CustomEventInit<TPayload>;
}

export const triggerCustomEvent = <TPayload>({
  type,
  eventInitDict,
}: UseCustomEventPayloadType<TPayload>): void => {
  const customEvent = new CustomEvent<TPayload>(type, eventInitDict);
  document.dispatchEvent(customEvent);
};
