import type { MouseEvent, ReactElement } from 'react';
import { memo, useState } from 'react';

import { Icons } from 'assets/icons';

import type { InputProps } from './types';

export const Input = memo((props: InputProps): ReactElement => {
  const { type, className, hasError = false, errorText, ...restProps } = props;

  const [fieldType, setFieldType] = useState<InputProps['type']>(type);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const secureMode = type === 'password';
  const borderColour = hasError
    ? 'border-error-100 focus:outline-none'
    : 'border-gray-400 focus:outline-orange-200';

  const stylesForSecureMode = secureMode ? 'pr-12' : '';
  const width = className?.includes('w-') ? '' : 'w-[20.9375rem]';
  const errorWidth = className?.includes('w-') ? 'w-56' : 'w-[20.9375rem]';

  const toggleShowPassword = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (fieldType === 'password') {
      setShowPassword(true);
      setFieldType('text');
    } else {
      setShowPassword(false);
      setFieldType('password');
    }
  };

  return (
    <div className={'relative'}>
      {hasError && errorText && (
        <div className={`${errorWidth}`}>
          <span className={'text-sm block'}>{errorText}</span>
        </div>
      )}
      <div className={'relative'}>
        <input
          {...restProps}
          type={fieldType}
          className={
            `${width} p-4 rounded-xl ` +
            `border ${borderColour} ${stylesForSecureMode} ` +
            'text-sm text-gray-700 ' +
            `placeholder:text-gray-600 placeholder:font-normal ${className}`
          }
        />
        {secureMode && (
          <div
            className={
              'absolute top-1/2 right-4 -translate-y-1/2 translate-x-0 cursor-pointer'
            }
            onMouseDown={toggleShowPassword}
          >
            {showPassword ? <Icons.SecurityOpen /> : <Icons.SecurityClose />}
          </div>
        )}
      </div>
    </div>
  );
});

Input.displayName = 'Input';
