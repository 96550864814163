import type { ReactElement } from 'react';
import { useNavigate, useRouteError } from 'react-router';

import { useTranslate, useVerifyRedirect } from 'hooks';

import { Images } from 'assets/images';
import { BaseButton, BaseButtonTemplates } from 'components';

import type { IRouteError } from './types';

export const ErrorPage = (): ReactElement | null => {
  const { t } = useTranslate('errorPage');
  const navigate = useNavigate();
  const error = useRouteError() as IRouteError;
  useVerifyRedirect();

  const goBack = (): void => {
    navigate('/');
  };

  return (
    <div
      className={`w-screen h-screen flex flex-col items-center gap-10 mt-24`}
    >
      <Images.LogoWithTitle />
      <span className={'text-7xl font-bold'}>{t('title')}</span>
      <span className={'font-bold'}>{t('unexpectedError')}</span>
      {error?.statusText && (
        <span className={'text-gray-400'}>{error.statusText}</span>
      )}
      <BaseButton template={BaseButtonTemplates.Fill} onClick={goBack}>
        {t('backButtonTitle')}
      </BaseButton>
    </div>
  );
};
