import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';

import { Paths } from 'common/constants/paths';

import {
  getIsLoggedInSelector,
  getProfileSelector,
  useAccountStore,
} from 'store';

import { useBreakpoint, useTranslate } from 'hooks';

import { ContestTimeInfo, MyVideos, UserData } from './components';
import { BaseModal, ContainerWithLogo, TextButton } from 'components';

//TODO: Add animations for inputs

enum ModalModes {
  LogOut = 'LogOut',
  DeleteAccount = 'DeleteAccount',
}

export const ProfilePage = (): ReactElement => {
  const { user, signOut, getUser, deleteAccount, updateProfile, removeAvatar } =
    useAccountStore(getProfileSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLoggedIn = useAccountStore(getIsLoggedInSelector);
  const { breakpoint } = useBreakpoint();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState<boolean>(false);

  const timeLeft =
    Number(user?.video?.contest?.time_left) <= 0
      ? 0
      : user?.video?.contest?.time_left ?? 0;

  const onClickSignOut = useCallback((): void => {
    signOut();
    navigate(`/${Paths.BaseAuth}`);
  }, []);

  const toggleIsOpen = useCallback(
    (value: boolean, modalMode: ModalModes) => (): void => {
      if (modalMode === ModalModes.DeleteAccount) {
        setIsOpenDeleteModal(value);
        return;
      }

      setIsOpenLogoutModal(value);
    },
    [],
  );

  const onClickTerms = useCallback(() => {
    navigate(`/${Paths.Terms}`);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getUser();
    }
  }, [isLoggedIn]);

  if (!user) {
    return <Navigate to={`/${Paths.Vote}`} />;
  }

  return (
    <ContainerWithLogo>
      <div className={'flex-center flex-col pt-9.5 gap-4'}>
        <UserData
          user={user}
          saveTitle={t('profilePage.saveTitle')}
          updateProfile={updateProfile}
          removeAvatar={removeAvatar}
        />
        {breakpoint <= 480 && (
          <TextButton title={t('profilePage.terms')} onClick={onClickTerms} />
        )}
        <TextButton
          title={t('profilePage.logoutTitle')}
          onClick={toggleIsOpen(true, ModalModes.LogOut)}
        />
        <TextButton
          title={t('profilePage.deleteTitle')}
          onClick={toggleIsOpen(true, ModalModes.DeleteAccount)}
        />
        {user.video && (
          <>
            {user.video.contest?.date && (
              <ContestTimeInfo
                contestDate={user.video.contest?.date}
                timeLeft={timeLeft}
              />
            )}
            <MyVideos video={user.video} />
          </>
        )}
        {isOpenDeleteModal && (
          <ProfileModal
            onClose={toggleIsOpen(false, ModalModes.DeleteAccount)}
            title={t('delete.title')}
            description={t('delete.description')}
            onConfirm={deleteAccount}
          />
        )}
        {isOpenLogoutModal && (
          <ProfileModal
            onClose={toggleIsOpen(false, ModalModes.LogOut)}
            title={t('logoutModal.title')}
            description={t('logoutModal.description')}
            onConfirm={onClickSignOut}
          />
        )}
      </div>
    </ContainerWithLogo>
  );
};

interface ProfileModalProps {
  onClose: () => void;

  title: string;
  description: string;
  onConfirm: () => void;
}

// eslint-disable-next-line react/no-multi-comp
const ProfileModal = (props: ProfileModalProps): ReactElement => {
  const { onClose, title, onConfirm, description } = props;
  const { t } = useTranslate('delete');

  return (
    <BaseModal onClose={onClose}>
      <div
        className={'flex flex-col items-center'}
        onClick={(e) => e.stopPropagation()}
      >
        <span className={'font-medium'}>{title}</span>
        <span>{description}</span>
        <div className={'flex gap-2 mt-4'}>
          <span
            onClick={onConfirm}
            className={'hover:text-orange-300 cursor-pointer duration-200'}
          >
            {t('confirm')}
          </span>
          <span
            onClick={onClose}
            className={'hover:text-orange-300 cursor-pointer duration-200'}
          >
            {t('cancel')}
          </span>
        </div>
      </div>
    </BaseModal>
  );
};
