import type { ReactElement } from 'react';
import { useState } from 'react';

import { ApprovedLayout } from '../ApprovedLayout';
import { Icons } from 'assets/icons';
import { VideoModal } from 'components';

import type { SuccessfulUploadProps } from './types';

export const SuccessfulUpload = (
  props: SuccessfulUploadProps,
): ReactElement => {
  const { video } = props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <div className={'flex-center flex-col'}>
      <div
        className={
          'flex-center w-[95vw] h-[53vw] md:w-125 md:h-82 mt-4.5 overflow-hidden rounded-lg relative'
        }
      >
        <img
          src={video.thumbnail}
          alt='thumbnail'
          className={'w-full h-auto object-center'}
        />
        <Icons.Play
          className={'absolute cursor-pointer press-effect'}
          onClick={openModal}
        />
      </div>
      <div className={'w-full px-4 md:px-0'}>
        <p className={'text-xl font-medium mt-7'}>{video.title}</p>
        <p className={'max-w-[95vw] md:max-w-[500px] text-xl font-medium '}>
          {video.description}
        </p>
        <ApprovedLayout
          title={'uploadTitle'}
          firstDescription={'firstDescriptionUpload'}
          secondDescription={'secondDescriptionUpload'}
          align={'left'}
        />
      </div>
      <VideoModal
        video={video}
        isOpen={modalOpen}
        setModalOpen={setModalOpen}
        disableBlur
      />
    </div>
  );
};
