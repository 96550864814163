import type { ReactElement } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IRule, RulesProps } from './types';

export const Rules = memo((props: RulesProps): ReactElement => {
  const { errors } = props;
  const { t } = useTranslation();

  const rules: IRule[] = t('uploadRules', { returnObjects: true });
  const renderRules = rules.map((rule) => {
    const { text, type } = rule;

    return (
      <span
        key={type}
        className={errors[type] ? 'text-error-100' : 'text-gray-700'}
      >
        {text}
      </span>
    );
  });

  return (
    <div className={'mx-8'}>
      <div className={'flex flex-col mt-9 gap-4 tracking-tight'}>
        {renderRules}
      </div>
    </div>
  );
});

Rules.displayName = 'Rules';
