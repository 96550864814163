import { useAccountStore } from 'store';

import axios from 'axios';

export const baseInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

baseInstance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401) {
      useAccountStore.getState().signOut();
    }

    return Promise.reject(error);
  },
);
