import { useEffect } from 'react';

import { getDeepLink, getOS } from 'utils/mobileRedirect';

import { OS } from 'types/defaultTypes';

interface IUseMobileRedirectParams {
  resetToken?: string | null;
  verify?: {
    userId: string;
    token: string;
    expires: string;
    signature: string;
  };
}

export const useMobileRedirect = (params: IUseMobileRedirectParams): void => {
  const { resetToken, verify } = params;

  useEffect(() => {
    if (resetToken) {
      switch (true) {
        case getOS() === OS.Android: {
          window.location.href = getDeepLink(
            `reset-password?token=${resetToken}`,
          );
          break;
        }
        case getOS() === OS.Ios: {
          window.location.href = getDeepLink(
            `reset-password?token=${resetToken}`,
          );
          break;
        }
        default:
          break;
      }
    }
  }, [resetToken]);

  useEffect(() => {
    if (verify) {
      const { userId, token, signature, expires } = verify;

      switch (true) {
        case getOS() === OS.Android: {
          window.location.href = getDeepLink(
            `confirm-email?expires=${expires}&signature=${signature}&token=${token}&id=${userId}`,
          );
          break;
        }

        case getOS() === OS.Ios: {
          window.location.href = getDeepLink(
            `confirm-email?expires=${expires}&signature=${signature}&token=${token}&id=${userId}`,
          );
          break;
        }

        default: {
          break;
        }
      }
    }
  }, [verify]);
};
