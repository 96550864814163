import type { ReactElement } from 'react';

import type { BaseButtonProps } from './types';
import { BaseButtonTemplates } from './types';

export const BaseButton = (props: BaseButtonProps): ReactElement => {
  const {
    children,
    template = BaseButtonTemplates.Fill,
    colour = 'bg-orange-300',
    textColour = 'text-constants-white',
    disabled = false,
    className,
    maxSizeEnable = true,
    addDisableStyle,
    ...restProps
  } = props;

  const disableButtonStyles: string =
    'border border-gray-300 bg-gray-200 text-gray-500';

  const maxSize: string = maxSizeEnable ? 'max-w-[20.9375rem]' : '';

  const getStyles = (template: BaseButtonTemplates): string => {
    switch (template) {
      case BaseButtonTemplates.Fill: {
        if (disabled || addDisableStyle) return disableButtonStyles;

        return `border border-constants-white ${colour} ${textColour} press-effect`;
      }
      case BaseButtonTemplates.Empty: {
        if (disabled || addDisableStyle) return disableButtonStyles;

        return 'border-2 border-orange-300';
      }
    }
  };

  return (
    <button
      {...restProps}
      disabled={disabled}
      className={
        `${className} ${maxSize} ${getStyles(template)} ` +
        'px-8 py-4 rounded-2xl font-rubik font-medium flex-center'
      }
    >
      {children}
    </button>
  );
};
