import type { ReactElement } from 'react';

import { useTranslate } from 'hooks';

import type { ApprovedLayoutProps } from './types';

export const ApprovedLayout = (props: ApprovedLayoutProps): ReactElement => {
  const { title, firstDescription, secondDescription, align } = props;
  const { t } = useTranslate('approvedLayout');

  const containerStyle = align === 'center' ? 'flex-center' : 'flex';
  const secondDescriptionStyle = align === 'center' ? 'text-center' : '';

  return (
    <div className={`${containerStyle} flex-col tracking-tight text-gray-700`}>
      <span className={'mt-3 mb-5 text-1.5xl font-bold'}>
        {t(title ?? 'title')}
      </span>
      <span className={'mb-3'}>{t(firstDescription ?? 'infoText')}</span>
      <span
        className={`max-w-[25.5rem] ${secondDescriptionStyle} whitespace-pre-line`}
      >
        {t(secondDescription ?? 'infoApprovedText')}
      </span>
    </div>
  );
};
