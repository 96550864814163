import type { ChangeEvent, ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { OptionsModal } from '../OptionsModal';
import { Skeleton } from '../Skeleton';
import { Icons } from 'assets/icons';
import { Images } from 'assets/images';

import type { IModalOption } from '../OptionsModal/types';
import type { AvatarProps } from './types';

const isFile = (avatar: File | string): avatar is File => {
  return (avatar as File).size !== undefined;
};

export const Avatar = (props: AvatarProps): ReactElement => {
  const { avatar, size = 'small', onChangeValue } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const smallMode = size === 'small';
  const avatarSize = smallMode ? 'w-10 h-10' : 'w-22 h-22 cursor-pointer';

  const onPickAvatarHandler = (): void => {
    setIsOpen(true);
  };

  const closeModal = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const onAvatarPick = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    onChangeValue?.(file);
    closeModal();
  };

  const removeAvatar = useCallback((): void => {
    setAvatarUrl('');
    onChangeValue?.(null);
    closeModal();
  }, []);

  const onClickAvatar = useCallback((): void => {
    inputRef.current?.click();
  }, []);

  const options: IModalOption[] = [
    {
      title: 'Choose from desktop',
      action: onClickAvatar,
    },
    { title: 'Remove current photo', action: removeAvatar },
    { title: 'Cancel', action: closeModal },
  ];

  useEffect(() => {
    if (isFile(avatar)) {
      avatar && setAvatarUrl(URL.createObjectURL(avatar));
    } else {
      setAvatarUrl(avatar);
    }
  }, [avatar]);

  return (
    <div
      className={`relative group ${avatarSize}`}
      onClick={onPickAvatarHandler}
    >
      {!loaded && avatarUrl && (
        <Skeleton className={`absolute ${avatarSize} rounded-full`} />
      )}
      {avatarUrl ? (
        <img
          className={`${avatarSize} rounded-full border-2 border-blue-100`}
          src={avatarUrl}
          alt={'avatar'}
          onLoad={() => setLoaded(true)}
        />
      ) : (
        <Images.EmptyAvatar className={`rounded-full ${avatarSize}`} />
      )}
      {!smallMode && (
        <>
          <div
            className={
              'opacity-0 group-hover:opacity-100 flex-center w-5 h-5 bg-orange-300 ' +
              'absolute top-[4.25rem] right-2 cursor-pointer rounded-full duration-200'
            }
          >
            <Icons.Pen
              width={10}
              height={10}
              className={'text-constants-white'}
            />
          </div>
          <input
            ref={inputRef}
            type={'file'}
            accept={'image/*'}
            className={'hidden'}
            onChange={onAvatarPick}
          />
        </>
      )}
      <OptionsModal
        isOpen={isOpen}
        setModalOpen={setIsOpen}
        options={options}
      />
    </div>
  );
};
